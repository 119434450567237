import React, { useEffect } from "react";
import { ErrorPage } from "./404";

// Move to About??
export const Contact = () => {
  useEffect(() => {
    document.title = "Contact | JobSiteUpdate";
  }, []);

  return (
    <div>
      <ErrorPage />
    </div>
  );
};
