import { Tab } from "@material-ui/core";

export function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      title={props.label}
      label={<span className="tabbed">Label</span>}
      {...props}
    />
  );
}
