import React from "react";

export const Contact = () => {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#FD9426",
          position: "relative",
          minHeight: "20vh",
        }}
      >
        {/* kept for spacing */}
        <div className="ghost" style={{ opacity: 0 }}>
          <p style={{ margin: 0, padding: 16 }}>
            Contact us for more information{" "}
          </p>
          <p>
            Consortium for Engineered Trenchless Technology (CETT)
            <br />
            7-389 DICE, 9211 116 Street NW
            <br />
            Edmonton, AB, Canada T6G 1H9
          </p>
          <p style={{ margin: 0, padding: 16 }}>
            Email: info@jobsiteupdate.com
            <br />
            Phone: (780) 492 5106
          </p>
        </div>

        <div
          style={{ paddingLeft: "50px", position: "absolute", bottom: "0" }}
          className="homeInfo"
        >
          <p>Contact us for more information </p>
          <p>
            Consortium for Engineered Trenchless Technology (CETT)
            <br />
            7-389 DICE, 9211 116 Street NW
            <br />
            Edmonton, AB, Canada T6G 1H9
          </p>
          <p>
            Email:{" "}
            <a
              href="mailto:info@jobsiteupdate.com"
              id="contactEmail"
              title="Email us"
            >
              info@jobsiteupdate.com
            </a>
            <br />
            Phone: (780) 492 5106
          </p>
        </div>
        <div
          className="homeInfo"
          style={{
            paddingRight: "50px",
            position: "absolute",
            bottom: "0",
            right: "0",
          }}
        >
          <p style={{ textAlign: "center" }}>Our partners</p>
          <div style={{ paddingBottom: "20px" }}>
            <a
              href="http://cett.ca/"
              target="_blank"
              rel="noreferrer"
              title="CETT Website"
            >
              <img src="cett.png" height="80px" alt="CETT Logo" />
            </a>
            <a
              href="https://www.ualberta.ca/index.html"
              target="_blank"
              rel="noreferrer"
              title="UAlberta Website"
            >
              <img
                src="uofa.png"
                height="80px"
                alt="University of Alberta Banner"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
