import React, { useEffect } from "react";
import { ErrorPage } from "./404";

export const About = () => {
  useEffect(() => {
    document.title = "About | JobSiteUpdate";
  }, []);

  return (
    <div id="aboutus">
      {/* About Us */}
      <ErrorPage />
    </div>
  );
};
