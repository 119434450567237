import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Typography,
  Card,
  Switch,
  Button,
  CardContent,
  CardActions,
} from "@material-ui/core";
import {
  creatDateAxis,
  creatYValuePer,
  reformDate,
  getMed,
  getAvg,
  getSTD,
  getDays,
  getSum,
  getTypes,
  getActivityType,
  toDecimal2,
  getUnit
} from "../data/process";
import { Firestore } from "../firebase/firestore";

export let Summary = (props, ref) => {
  useImperativeHandle(ref, () => ({
    showData: (dataType, unit) => {
      handleGetData(dataType, unit);
    },
  }));
  const uid = props.uid;
  const pid = props.drawerInput.project;
  const viewStartDate = new Date(props.startDate);
  const viewEndDate = new Date(props.endDate + "T23:59:59");
  const [numDataEntry, setNumDataEntry] = useState();
  const [numDays, setNumDays] = useState();
  const [numHours, setNumHours] = useState();
  const [problemTypes, setProblemTypes] = useState();
  const [totalComplition, setTotalComplition] = useState();
  const [avgDay, setAvgDay] = useState();
  const [medDay, setMedDay] = useState();
  const [avgHour, setAvgHour] = useState();

  const handleGetData = (dataType, unit) => {
    console.log(dataType, unit,props.drawerInput.displayLevel)
    //reset
    setNumDataEntry(null);
    setNumDays(null);
    setNumHours(null);
    setAvgDay(null);
    setMedDay(null);
    setAvgHour(null);

    if (props.drawerInput.displayLevel === "Activity") {
      let activityType = getActivityType(dataType).ac;
      let subActivityType = getActivityType(dataType).sub;
      if (props.drawerInput.projectStage === "Activities") {
        Firestore.Activity.GetDataActivityLevel(
          pid,
          viewStartDate,
          viewEndDate,
          activityType,
          subActivityType
        ).then((snapshot) => {
          if (snapshot.size !== 0) {
            var daysData = snapshot.docs.map((doc) => {
              return reformDate(doc.data().dateTime.toDate());
            });
            var hoursData = snapshot.docs.map((doc) => {
              return doc.data().numHours;
            });
            var lengthData = snapshot.docs.map((doc) => {
              let value = null;
              if (
                dataType === "Drilling/Conduit Placing" ||
                dataType === "Hand Pulling/Distribution Cable" ||
                dataType === "Jetting/Distribution Cable"
              ) {
                value = doc.data().length;
                if (doc.data().unit === "Inch") {
                  value = value * 0.0254;
                }
                if (doc.data().unit === "Feet") {
                  value = value * 0.3048;
                }
                return value;
              } else if (dataType === "FP/Drop Stubouts") {
                return doc.data().quantity;
              } else if (dataType === "Hydrovac") {
                return doc.data().numPotholes;
              } else if (
                dataType === "Hand Pulling/Drops" ||
                dataType === "Jetting/Drops"
              ) {
                return parseFloat(
                  doc
                    .data()
                    .dropLength.slice(0, doc.data().dropLength.length - 1)
                );
              } else if (
                dataType === "Hand Pulling/Terminal Tails" ||
                dataType === "Jetting/Terminal Tails"
              ) {
                return doc.data().metersPlaced;
              } else if (dataType === "OTDR") {
                return doc.data().numFibers;
              } else if (dataType === "Power Meter") {
                return doc.data().numFibers;
              } else if (dataType === "Ruby Red") {
                return doc.data().numFibersPL;
              } else if (dataType === "SB Placement") {
                return doc.data().numUnits;
              } else if (dataType === "Splicing") {
                return doc.data().numWL;
              }
            });
            setNumDataEntry(snapshot.size);
            setNumDays(getDays(daysData));
            setNumHours(getSum(hoursData));
            setTotalComplition(getSum(lengthData));
            setAvgDay(getAvg(lengthData) / getDays(daysData));
            setAvgHour(getAvg(creatYValuePer(lengthData, hoursData)));
          } else {
            setNumDataEntry(0);
            setNumDays(0);
            setNumHours(0);
            setTotalComplition(0);
            setAvgDay(0);
            setAvgHour(0);
          }
        });
      } else {
        //Problem
        Firestore.Problem.GetActivityProblem(
          pid,
          viewStartDate,
          viewEndDate,
          activityType,
          subActivityType
        ).then((snapshot) => {
          if (snapshot.size !== 0) {
            var daysData = snapshot.docs.map((doc) => {
              return reformDate(doc.data().dateTime.toDate());
            });
            var hoursData = snapshot.docs.map((doc) => {
              return doc.data().numHours;
            });
            var typeData = snapshot.docs.map((doc) => {
              return doc.data().issueType;
            });
            setNumDataEntry(snapshot.size);
            setNumDays(getDays(daysData));
            setNumHours(getSum(hoursData));
            setProblemTypes(getTypes(typeData).length);
            setAvgDay(getAvg(hoursData));
            setMedDay(getMed(hoursData));
          } else {
            setNumDataEntry(0);
            setNumDays(0);
            setNumHours(0);
            setProblemTypes(0);
            setAvgDay(0);
            setMedDay(0);
          }
        });
      }
    } else if (props.drawerInput.displayLevel === "Category") {
      Firestore.Activity.GetCategoryData(
        pid,
        viewStartDate,
        viewEndDate,
        dataType
      ).then((snapshot) => {
        if (snapshot.size !== 0) {
          var daysData = snapshot.docs.map((doc) => {
            return reformDate(doc.data().dateTime.toDate());
          });
          var hoursData = snapshot.docs.map((doc) => {
            return doc.data().numHours;
          });
          setNumDataEntry(snapshot.size);
          setNumDays(
            creatDateAxis(
              snapshot.docs[0].data().dateTime.toDate(),
              snapshot.docs[snapshot.size - 1].data().dateTime.toDate()
            ).length
          );
          setNumHours(getSum(hoursData));
        } else {
          setNumDataEntry(0);
          setNumDays(0);
          setNumHours(0);
        }
      });
    } else {
      Firestore.Activity.GetAllActivity(pid, viewStartDate, viewEndDate).then(
        (snapshot) => {
          if (snapshot.size !== 0) {
            var daysData = snapshot.docs.map((doc) => {
              return reformDate(doc.data().dateTime.toDate());
            });
            var hoursData = snapshot.docs.map((doc) => {
              return doc.data().numHours;
            });
            setNumDataEntry(snapshot.size);
            setNumDays(
              creatDateAxis(
                snapshot.docs[0].data().dateTime.toDate(),
                snapshot.docs[snapshot.size - 1].data().dateTime.toDate()
              ).length
            );
            setNumHours(getSum(hoursData));
          } else {
            setNumDataEntry(0);
            setNumDays(0);
            setNumHours(0);
          }
        }
      );
    }
  };

  const summaryItems = (dataType) => {
    if (props.drawerInput.displayLevel === "Activity") {
      if (props.drawerInput.projectStage === "Activities") {
        return (
          <>
            <p>Total complition: {toDecimal2(totalComplition)} {getUnit(dataType)}</p>
            <p>Average Daily Completion Rate: {toDecimal2(avgDay)} {getUnit(dataType)}/day</p>
            <p>Average Hourly Completion Rate: {toDecimal2(avgHour)} {getUnit(dataType)}/hr</p>
          </>
        );
      } else {
        //Problem Stage
        return (
          <>
            <p>Total number of problem type: {problemTypes}</p>
            <p>Average Daily Problem rate: {toDecimal2(avgDay)} {getUnit(dataType)}/day</p>
            <p>Median Daily Problem rate: {toDecimal2(medDay)} {getUnit(dataType)}/day</p>
          </>
        );
      }
    } else if (props.drawerInput.displayLevel === "Category") {
    } else {
      //Project Stage
    }
  };

  return (
    <div className="summaryContainer">
      <Card className="summaryCard" raised={true}>
        <CardContent className="summaryCardContent">
          <h3>Total</h3>
          <p>Total number of data entry: {numDataEntry}</p>
          <p>Total number of days: {numDays}</p>
          <p>Total number of hours: {toDecimal2(numHours)}</p>
          {summaryItems(props.barInput.dataType)}
        </CardContent>
        {/* <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    
      <Card className="summaryCard" raised={true}>
        <CardContent className="summaryCardContent">
          <h3>Plan</h3>
          <p>Planned number of days:</p>
          <p>Planned number of hours:</p>
          <p>Planned Completion:</p>
          <p>Planned Average Daily Completion rate:</p>
        </CardContent>
        {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </Card>
      {/* <Button onClick={()=>handleGetData(props.drawerInput.data)}>
          GET DATA
        </Button> */}
    </div>
  );
};
Summary = forwardRef(Summary);
