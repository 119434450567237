/** get activity name and subactivity name*/
export const getActivityType = (dataType) => {
  let activityType = null;
  let subActivityType = null;
  if (dataType === "Drilling/Conduit Placing") {
    activityType = "Drilling / Conduit Placement";
  } else if (dataType === "FP/Drop Stubouts") {
    activityType = "FD / Drop Stubouts";
  } else if (dataType === "Hydrovac") {
    activityType = "Hydrovac";
  } else if (dataType === "Hand Pulling/Distribution Cable") {
    activityType = "Hand Pulling";
    subActivityType = "Distribution Cable";
  } else if (dataType === "Hand Pulling/Drops") {
    activityType = "Hand Pulling";
    subActivityType = "Drops";
  } else if (dataType === "Hand Pulling/Terminal Tails") {
    activityType = "Hand Pulling";
    subActivityType = "Terminal Tails";
  } else if (dataType === "Jetting/Distribution Cable") {
    activityType = "Jetting";
    subActivityType = "Distribution Cable";
  } else if (dataType === "Jetting/Drops") {
    activityType = "Jetting";
    subActivityType = "Drops";
  } else if (dataType === "Jetting/Terminal Tails") {
    activityType = "Jetting";
    subActivityType = "Terminal Tails";
  } else if (dataType === "OTDR") {
    activityType = "OTDR";
  } else if (dataType === "Power Meter") {
    activityType = "Power Meter";
  } else if (dataType === "Ruby Red") {
    activityType = "Ruby Red";
  } else if (dataType === "SB Placement") {
    activityType = "SB Placement";
  } else if (dataType === "Splicing") {
    activityType = "Splicing";
  }
  return { ac: activityType, sub: subActivityType };
};

export const getUnit = (dataType) => {
  var unit = "";
  if (dataType === "Drilling/Conduit Placing") {
    unit = "m";
  } else if (dataType === "FP/Drop Stubouts") {
    unit = "drop";
  } else if (dataType === "Hydrovac") {
    unit = "hole";
  } else if (dataType === "Hand Pulling/Distribution Cable") {
    unit = "m";
  } else if (dataType === "Hand Pulling/Drops") {
    unit = "m";
  } else if (dataType === "Hand Pulling/Terminal Tails") {
    unit = "m";
  } else if (dataType === "Jetting/Distribution Cable") {
    unit = "m";
  } else if (dataType === "Jetting/Drops") {
    unit = "m";
  } else if (dataType === "Jetting/Terminal Tails") {
    unit = "m";
  } else if (dataType === "OTDR") {
    unit = "OTDR";
  } else if (dataType === "Power Meter") {
    unit = "fiber";
  } else if (dataType === "Ruby Red") {
    unit = "fiber";
  } else if (dataType === "SB Placement") {
    unit = "unit";
  } else if (dataType === "Splicing") {
    unit = "WL";
  }
  return unit;
};
/**round to 2 decimal places */
export const toDecimal2 = (x) => {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
};

/**
 * reform the date to readable format.
 * @param date
 * @returns reformed date: year-month-day
 */
export const reformDate = (date) => {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  return y + "-" + m + "-" + d;
};

/**
 * calculate working rate for drilling
 * @param length (string)
 * @param hour (string)
 * @returns rate
 */
export const calRate = (length, hour) => {
  var res = parseFloat(length) / parseFloat(hour);
  res = parseFloat(res.toFixed(2));
  return res;
};

/**
 * calculate working rate per crew for drilling
 * @param rate (float)
 * @param crewNum (string)
 * @returns ratePerCrew
 */
export const calRatePerCrew = (rate, crewNum) => {
  var res = rate / parseFloat(crewNum);
  res = parseFloat(res.toFixed(2));
  return res;
};

/**
 * creat the x axis(date) for graph
 * @param startTime (date)
 * @param endTime (date)
 * @returns an array with dates between the start and end date
 */
export const creatDateAxis = (startTime, endTime) => {
  var xAxis = [];
  xAxis.push(reformDate(startTime));
  while (reformDate(startTime) !== reformDate(endTime)) {
    startTime.setDate(startTime.getDate() + 1);
    xAxis.push(reformDate(startTime));
  }
  return xAxis;
};

/**
 * creat the y values for graph without any calculation
 * @param xAxis an array with dates between the start and end date
 * @param data an array of objects contains date and value
 * @returns an array of value between the start and end date
 */
export const creatYRawValue = (xAxis, data) => {
  var seriesData = [];
  let j = 0;
  for (let i = 0; i < xAxis.length; i++) {
    var value = 0;
    for (let j = 0; j < data.length; j++) {
      if (data[j].date === xAxis[i]) {
        value += data[j].value;
      }
    }
    seriesData.push(value);
  }
  return seriesData;
};

/**
 * creat the y values per unit
 * @param data an array
 * @param unit an array
 * @returns an array of value between the start and end date
 */
export const creatYValuePer = (data, unit) => {
  var seriesData = new Array();
  for (let i = 0; i < data.length; i++) {
    if (unit[i] !== 0) {
      seriesData.push(data[i] / unit[i]);
    } else {
      seriesData.push(0);
    }
  }
  return seriesData;
};

/**
 * creat y values for multiple objects , without any calculation
 * @param xAxis an array with dates between the start and end date
 * @param data an array of objects contains date, type and value
 * @returns an array of value between the start and end date
 */
export const creatYRawValueMulti = (xAxis, data) => {
  var name = [];
  for (let i = 0; i < data.length; i++) {
    if (name.indexOf(data[i].type) === -1) {
      name.push(data[i].type);
    }
  }
  var seriesData = new Array();
  for (let i = 0; i < name.length; i++) {
    var seriesItem = new Array();
    for (let j = 0; j < xAxis.length; j++) {
      var value = 0;
      for (let k = 0; k < data.length; k++) {
        if (data[k].date === xAxis[j] && data[k].type === name[i]) {
          value += data[k].value;
        }
      }
      seriesItem.push(value);
    }
    seriesData.push({ name: name[i], data: seriesItem });
  }
  return seriesData;
};

/**
 * creat y values for pie chart(hr)
 * @param data an array of objects contains date, type and value
 * @returns an array of objects
 */
export const creatYPieRaw = (data) => {
  var name = [];
  for (let i = 0; i < data.length; i++) {
    if (name.indexOf(data[i].type) === -1) {
      name.push(data[i].type);
    }
  }
  var seriesData = new Array();
  for (let i = 0; i < name.length; i++) {
    var sum = 0;
    for (let j = 0; j < data.length; j++) {
      if (data[j].type === name[i]) {
        sum += data[j].value;
      }
    }
    seriesData.push({ name: name[i], y: sum });
  }
  return seriesData;
};

/**
 * creat y values for pie chart (%)
 * @param data an array of objects contains date, type and value
 * @returns an array of objects
 */
export const creatYPiePercent = (data) => {
  var rawData = creatYPieRaw(data);
  var sum = 0;
  for (let i = 0; i < rawData.length; i++) {
    sum += rawData[i].y;
  }
  var res = [];
  for (let i = 0; i < rawData.length; i++) {
    res.push({
      name: rawData[i].name,
      y: parseFloat(((rawData[i].y / sum) * 100).toFixed(2)),
    });
  }
  return res;
};

/**
 * get median of an array
 * @param inputArr an array
 * @returns an integer
 */
export const getMed = (inputArr) => {
  var arr = inputArr.concat([]).sort();
  //remove empty value 0
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] !== 0) {
      break;
    }
  }
  arr = arr.splice(i, inputArr.length - 1);

  if (arr.length % 2 === 0) {
    return (arr[arr.length / 2 - 1] + arr[arr.length / 2]) / 2;
  } else {
    return arr[Math.floor(arr.length / 2)];
  }
};

/**
 * get sum of an array
 * @param inputArr an array
 * @returns an float
 */
export const getSum = (inputArr) => {
  var sum = 0;
  for (let i = 0; i < inputArr.length; i++) {
    if (inputArr[i] !== 0) {
      sum += inputArr[i];
    }
  }
  return sum;
};

/**
 * get average of non-zero values in an array
 * @param inputArr an array
 * @returns an float
 */
export const getAvg = (arr) => {
  var avg = 0;
  var count = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== 0) {
      avg += arr[i];
      count++;
    }
  }
  return avg / count;
};

/**
 * get STD of an array
 * @param inputArr an array
 * @returns an float
 */
export const getSTD = (inputArr) => {
  var arr = inputArr.concat([]).sort();
  //remove empty value 0
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] !== 0) {
      break;
    }
  }
  var avg = getAvg(arr);
  var sums = 0;
  for (let i = 0; i < arr.length; i++) {
    sums += (arr[i] - avg) * (arr[i] - avg);
  }
  return Math.sqrt(sums / arr.length);
};

/**
 * get the number of non-repeatable dates
 * @param inputArr an array
 * @returns an int
 */
export const getDays = (inputArr) => {
  return Array.from(new Set(inputArr)).length;
};

/**
 * get the types of the activity
 * @param inputArr an array
 * @returns an array
 */
export const getTypes = (inputArr) => {
  var name = [];
  for (let i = 0; i < inputArr.length; i++) {
    if (name.indexOf(inputArr[i]) === -1) {
      name.push(inputArr[i]);
    }
  }
  return name;
};

/**
 * get the sum of the input value for each activity type
 */
export const getTypeSum = (inputArr, hourOnly) => {
  var name = [];
  for (let i = 0; i < inputArr.length; i++) {
    if (
      inputArr[i].category === "Splicing" &&
      name.indexOf("Splicing") === -1
    ) {
      name.push("Splicing");
      break;
    }
    if (
      inputArr[i].subActivityType !== null &&
      name.indexOf(
        inputArr[i].activityType + "/" + inputArr[i].subActivityType
      ) === -1
    ) {
      name.push(inputArr[i].activityType + "/" + inputArr[i].subActivityType);
    } else if (
      inputArr[i].subActivityType === null &&
      name.indexOf(inputArr[i].activityType) === -1
    ) {
      name.push(inputArr[i].activityType);
    }
  }
  //sort in type
  var resValue = [];
  var resHour = [];
  for (let i = 0; i < name.length; i++) {
    var sumValue = 0;
    var sumHour = 0;
    for (let j = 0; j < inputArr.length; j++) {
      if (
        inputArr[j].category === name[i] ||
        inputArr[j].activityType + "/" + inputArr[j].subActivityType ===
          name[i] ||
        inputArr[j].activityType === name[i]
      ) {
        if (!hourOnly) {
          sumValue += inputArr[j].value;
        }
        sumHour += inputArr[j].numHours;
      }
    }
    if (!hourOnly) {
      resValue.push(sumValue);
    }
    resHour.push(sumHour);
  }
  if (!hourOnly) {
    return { name: name, value: resValue, hour: resHour };
  }
  return { name: name, hour: resHour };
};

/**
 * get the daily average of the input value for each activity type
 */
export const getTypeDailyAvg = (inputArr) => {
  var name = [];
  for (let i = 0; i < inputArr.length; i++) {
    if (name.indexOf(inputArr[i].activityType) === -1) {
      name.push(inputArr[i].activityType);
    }
  }
  var res = [];
  for (let i = 0; i < name.length; i++) {
    var dailyValue = [];
    for (let j = 0; j < inputArr.length; j++) {
      if (inputArr[j].activityType === name[i]) {
        dailyValue.push(inputArr[j].value / inputArr[j].numHours);
      }
      res.push(getAvg(dailyValue));
    }
  }
  return res;
};

/**
 * get the sum of the day for each category
 */
export const getTotalDay = (start, end) => {
  var reStart = new Date(
    start.getFullYear(),
    start.getMonth(),
    start.getDate(),
    23,
    59,
    59
  );
  var reEnd = new Date(
    end.getFullYear(),
    end.getMonth(),
    end.getDate(),
    0,
    0,
    0
  );
  var len = parseInt((reEnd - reStart) / (1000 * 60 * 60 * 24));
  if (reformDate(start) == reformDate(end)) {
    return len + 1;
  } else {
    return len + 2;
  }
};

export const getProjectData = (activityData, problemData) => {
  var names = [];
  for (let i = 0; i < activityData.length; i++) {
    if (names.indexOf(activityData[i].category) === -1) {
      names.push(activityData[i].category);
    }
  }
  //Sort in category
  var sortedActivity = [];
  for (let i = 0; i < names.length; i++) {
    var categoryData = [];
    for (let j = 0; j < activityData.length; j++) {
      if (activityData[j].category === names[i]) {
        categoryData.push(activityData[j]);
      }
    }
    sortedActivity.push(categoryData);
  }
  if(problemData!==null){
    var sortedProblem = [];
    for (let i = 0; i < names.length; i++) {
      var categoryData = [];
      for (let j = 0; j < problemData.length; j++) {
        if (problemData[j].category === names[i]) {
          categoryData.push(problemData[j]);
        }
      }
      sortedProblem.push(categoryData);
    }
  }

  var startTime = null;
  var endTime = null;
  var acDays = [];
  var acHours = [];
  for (let i = 0; i < sortedActivity.length; i++) {
    startTime = sortedActivity[i][0].dateTime.toDate();
    endTime = sortedActivity[i][sortedActivity[i].length - 1].dateTime.toDate();
    acDays.push(getTotalDay(startTime, endTime));
    var hourSum = 0;
    for (let j = 0; j < sortedActivity[i].length; j++) {
      hourSum += sortedActivity[i][j].numHours;
    }
    acHours.push(hourSum);
  }

  if(problemData!==null){
    var problemDays = [];
    var problemHours = [];
    for (let i = 0; i < sortedProblem.length; i++) {
      startTime = sortedProblem[i][0].dateTime.toDate();
      endTime = sortedProblem[i][sortedProblem[i].length - 1].dateTime.toDate();
      problemDays.push(getTotalDay(startTime, endTime));
      var hourSum = 0;
      for (let j = 0; j < sortedProblem[i].length; j++) {
        hourSum += sortedProblem[i][j].numHours;
      }
      problemHours.push(hourSum);
    }
  }



  let data = [];
  for (let i = 0; i < sortedActivity.length; i++) {
    if(problemData!==null){
      data.push({
        //TODO change the planned value
        id: i,
        //user:null,
        category: names[i],
        totalHour: toDecimal2(acHours[i] + problemHours[i]),
        totalDay: acDays[i] + problemDays[i],
        plannedHour: null,
        plannedDay: null,
        bp: null,
        relativeActivity: toDecimal2(
          ((acHours[i] + problemHours[i]) /
            (getSum(acHours) + getSum(problemHours))) *
            100
        ),
        relativeTotal: toDecimal2(
          ((acDays[i] + problemDays[i]) /
            (getSum(acDays) + getSum(problemDays))) *
            100
        ),
        problemHour: problemHours[i],
        problemDay: problemDays[i],
        problemPercentage: toDecimal2(
          (problemHours[i] / (problemHours[i] + acHours[i])) * 100
        ),
      });
    }else{
      data.push({
        //TODO change the planned value
        id: i,
        category: names[i],
        totalHour: toDecimal2(acHours[i]),
        totalDay: acDays[i]+0,
        plannedHour: null,
        plannedDay: null,
        bp: null,
        relativeActivity: toDecimal2(
          ((acHours[i] + 0) /
            (getSum(acHours) + 0)) *
            100
        ),
        relativeTotal: toDecimal2(
          ((acDays[i] + 0) /
            (getSum(acDays) + 0)) *
            100
        ),
        problemHour: 0,
        problemDay: 0,
        problemPercentage: 0
      });
    }
  
  }
  return data;
};

export const getSequenceData = (acHour, probHour) => {
  var res = [];
  for (let i = 0; i < acHour.length; i++) {
    let year = acHour[i].date.getFullYear();
    let month = acHour[i].date.getMonth();
    let day = acHour[i].date.getDate();
    let hour = acHour[i].date.getHours();
    let minute = acHour[i].date.getMinutes();
    res.push({
      x: Date.UTC(year, month, day, hour),
      x2: Date.UTC(
        year,
        month,
        day,
        hour + parseInt(acHour[i].value),
        minute + 60 * (acHour[i].value - parseInt(acHour[i].value))
      ),
      y: 0,
    });
  }
  for (let i = 0; i < probHour.length; i++) {
    let year = probHour[i].rawDate.getFullYear();
    let month = probHour[i].rawDate.getMonth();
    let day = probHour[i].rawDate.getDate();
    let hour = probHour[i].rawDate.getHours();
    let minute = probHour[i].rawDate.getMinutes();
    res.push({
      x: Date.UTC(year, month, day, hour, minute),
      x2: Date.UTC(
        year,
        month,
        day,
        hour + parseInt(probHour[i].value),
        minute + 60 * (probHour[i].value - parseInt(probHour[i].value))
      ),
      y: 1,
    });
  }
  return res;
};

export const getSequenceDataMulti = (acHour, probHour, names) => {
  //sort in type
  var sortedActivity = [];
  for (let i = 0; i < names.length; i++) {
    var typeData = [];
    for (let j = 0; j < acHour.length; j++) {
      if (acHour[j].type === names[i]) {
        typeData.push(acHour[j]);
      }
    }
    sortedActivity.push(typeData);
  }

  var res = [];
  for (let i = 0; i < names.length; i++) {
    for (let j = 0; j < sortedActivity[i].length; j++) {
      let year = sortedActivity[i][j].date.getFullYear();
      let month = sortedActivity[i][j].date.getMonth();
      let day = sortedActivity[i][j].date.getDate();
      let hour = sortedActivity[i][j].date.getHours();
      let minute = sortedActivity[i][j].date.getMinutes();
      res.push({
        x: Date.UTC(year, month, day, hour, minute),
        x2: Date.UTC(
          year,
          month,
          day,
          hour + parseInt(sortedActivity[i][j].value),
          minute +
            60 *
              (sortedActivity[i][j].value -
                parseInt(sortedActivity[i][j].value))
        ),
        y: i,
      });
    }
  }
  if (probHour !== null) {
    for (let i = 0; i < probHour.length; i++) {
      let year = probHour[i].date.getFullYear();
      let month = probHour[i].date.getMonth();
      let day = probHour[i].date.getDate();
      let hour = probHour[i].date.getHours();
      let minute = probHour[i].date.getMinutes();
      res.push({
        x: Date.UTC(year, month, day, hour),
        x2: Date.UTC(
          year,
          month,
          day,
          hour + parseInt(probHour[i].value),
          minute + 60 * (probHour[i].value - parseInt(probHour[i].value))
        ),
        y: names.length - 1,
      });
    }
  }
  console.log("seriesData",res)
  return res;
};

export const getSequenceDataDay = (acHour, probHour) => {
  var res = [];
  for (let i = 0; i < acHour.length; i++) {
    let year = acHour[i].date.getFullYear();
    let month = acHour[i].date.getMonth();
    let day = acHour[i].date.getDate();
    res.push({
      x: Date.UTC(year, month, day),
      x2: Date.UTC(year, month, day + 1),
      y: 0,
    });
  }
  for (let i = 0; i < probHour.length; i++) {
    let year = probHour[i].rawDate.getFullYear();
    let month = probHour[i].rawDate.getMonth();
    let day = probHour[i].rawDate.getDate();
    res.push({
      x: Date.UTC(year, month, day),
      x2: Date.UTC(year, month, day + 1),
      y: 1,
    });
  }
  return res;
};

export const getSequenceDataDayMulti = (acHour, probHour, names) => {
  //sort in type
  var sortedActivity = [];
  for (let i = 0; i < names.length; i++) {
    var typeData = [];
    for (let j = 0; j < acHour.length; j++) {
      if (acHour[j].type === names[i]) {
        typeData.push(acHour[j]);
      }
    }
    sortedActivity.push(typeData);
  }
  var res = [];
  for (let i = 0; i < names.length; i++) {
    for (let j = 0; j < sortedActivity[i].length; j++) {
      let year = sortedActivity[i][j].date.getFullYear();
      let month = sortedActivity[i][j].date.getMonth();
      let day = sortedActivity[i][j].date.getDate();
      res.push({
        x: Date.UTC(year, month, day),
        x2: Date.UTC(year, month, day + 1),
        y: i,
      });
    }
  }
  if(probHour!==null){
    for (let i = 0; i < probHour.length; i++) {
      let year = probHour[i].date.getFullYear();
      let month = probHour[i].date.getMonth();
      let day = probHour[i].date.getDate();
      res.push({
        x: Date.UTC(year, month, day),
        x2: Date.UTC(year, month, day + 1),
        y: names.length - 1,
      });
    }
  }

  return res;
};

export const getPercentage = (acHour, probHour) => {
  let ac = acHour.map((data) => data.value);
  let prob = probHour.map((data) => data.value);
  ac = getSum(ac);
  prob = getSum(prob);
  return {
    ac: ((ac / (ac + prob)) * 100).toFixed(2),
    prob: ((prob / (ac + prob)) * 100).toFixed(2),
  };
};

export const getPercentageMulti = (acHour, probHour, names) => {
  //sort in type
  var sortedActivity = [];
  for (let i = 0; i < names.length; i++) {
    var typeData = [];
    for (let j = 0; j < acHour.length; j++) {
      if (acHour[j].type === names[i]) {
        typeData.push(acHour[j]);
      }
    }
    sortedActivity.push(typeData);
  }
  let res = new Array(names.length);
  for (let i = 0; i < names.length; i++) {
    let ac = sortedActivity[i].map((data) => data.value);
    res[i] = getSum(ac);
  }
  if(probHour!== null){
    let prob = probHour.map((data) => data.value);
  res[res.length - 1] = getSum(prob);
  }
 
  let sum = getSum(res);
  for (let i = 0; i < res.length; i++) {
    if (sum !== 0) {
      res[i] = parseFloat(((res[i] / sum) * 100).toFixed(2));
    } else {
      res[i] = 0;
    }
  }
  return res;
};

/**
 * between the date of the first activity/problem and the date of the latest activity/problem,
 * how many days have activity and how many days have problems
 * @param {*} acHour
 * @param {*} probHour
 * @returns
 */
export const getPercentageDay = (acHour, probHour) => {
  let ac = acHour.map((data) => reformDate(data.date));
  let prob = probHour.map((data) => reformDate(data.rawDate));
  let startTime = ac[0] < prob[0] ? ac[0] : prob[0];
  let endTime =
    ac[ac.length - 1] > prob[prob.length - 1]
      ? ac[ac.length - 1]
      : prob[prob.length - 1];
  ac = getDays(ac);
  prob = getDays(prob);
  let res = creatDateAxis(new Date(startTime), new Date(endTime)).length;
  return { ac: (ac / res) * 100, prob: (prob / res) * 100 };
};

export const getPercentageDayMulti = (acHour, probHour, names) => {
  //sort in type
  var sortedActivity = [];
  for (let i = 0; i < names.length; i++) {
    var typeData = [];
    for (let j = 0; j < acHour.length; j++) {
      if (acHour[j].type === names[i]) {
        typeData.push(acHour[j]);
      }
    }
    sortedActivity.push(typeData);
  }
  let res = new Array(names.length);
  let startTime = [];
  let endTime = [];
  for (let i = 0; i < names.length; i++) {
    let ac = sortedActivity[i].map((data) => reformDate(data.date));
    res[i] = getDays(ac);
    startTime.push(ac[0]);
    endTime.push(ac[ac.length - 1]);
  }
  if(probHour!==null){
    let prob = probHour.map((data) => reformDate(data.date));
    res[res.length - 1] = getDays(prob);
    startTime.push(prob[0]);
    endTime.push(prob[prob.length - 1]);
  }
  let min = startTime[0];
  let max = endTime[0];
  for (let i = 1; i < startTime.length; i++) {
    if (startTime[i] < min) {
      min = startTime[i];
    }
    if (endTime[i] > max) {
      max = endTime[i];
    }
  }

  let sumDays = creatDateAxis(new Date(min), new Date(max)).length;
  return res.map((data) => parseFloat(((data / sumDays) * 100).toFixed(2)));
};

export const getProblemtoActivityPercentage = (xAxis, acHour, probHour) => {
  var acData = new Array();
  var probData = new Array();
  let j = 0;
  for (let i = 0; i < xAxis.length; i++) {
    var value = 0;
    for (let j = 0; j < acHour.length; j++) {
      if (reformDate(acHour[j].date) === xAxis[i]) {
        value += acHour[j].value;
      }
    }
    acData.push(value);
    value = 0;
    for (let j = 0; j < probHour.length; j++) {
      if (probHour[j].date === xAxis[i]) {
        value += probHour[j].value;
      }
    }
    probData.push(value);
  }
  var seriesData = new Array();
  for (let i = 0; i < acData.length; i++) {
    if (acData[i] + probData[i] !== 0) {
      seriesData.push(
        parseFloat(((probData[i] / (acData[i] + probData[i])) * 100).toFixed(2))
      );
    } else {
      seriesData.push(0);
    }
  }

  return seriesData;
};

export function isObjectValueEqual(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    var propA = a[propName];
    var propB = b[propName];
    if (propA !== propB) {
      return false;
    }
  }
  return true;
}
