import React from "react";
import { Contact } from "../component/Contact";
import { Footer } from "../component/Footer";

export const ErrorPage = () => {
  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          width: "100vw",
          display: "inline-block",
          alignSelf: "flex-end",
        }}
      >
        <div
          className="errorPage"
          style={{
            height: "50vh",
            backgroundImage: `url(${"footer.png"})`,
            backgroundPosition: "right bottom",
            backgroundSize: "100% auto",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h1>Error 404</h1>
          <h2>Page Not Found</h2>
        </div>
        <Contact />
        <Footer />
      </div>
    </div>
  );
};
