import React, { useRef, useImperativeHandle, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Typography,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  NativeSelect,
} from "@material-ui/core";

import { EnhancedTable } from "../component/Tabular";
import { TabPanel } from "../component/TabPanel";
import { LinkTab } from "../component/LinkTab";
import { styles } from "../style/style";
import { Summary } from "../component/Summary";
import { Graphical } from "../component/Graphical";

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}


export let ViewDataNav = (props, ref) => {
  useImperativeHandle(ref, () => ({
    resetOption: () => {
      setInputState({
        dataType: "",
        unit: "",
      });
    },
  }));
  const classes = styles();
  const [navValue, setNavValue] = React.useState(0);
  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    setInputState({
      dataType: "",
      unit: "",
    });
  };
  const [inputState, setInputState] = React.useState({
    dataType: "",
    unit: "",
  });
  const tableRef = useRef();
  const graphRef = useRef();
  const summaryRef = useRef();
  const updateChildShowData = (dataType, unit) => {
    console.log("updateChildShowData")
    console.log(dataType)
    console.log(unit)
    if (navValue === 0) {
      if(dataType!==""){
        tableRef.current.showData(dataType, unit);
      }
    } else if (navValue === 1) {
      if(dataType!==""&&unit!==""){
        graphRef.current.showData(dataType, unit);
      }
    } else if (navValue === 2) {
      if(dataType!==""){
        summaryRef.current.showData(dataType, unit);
      }
    }
  };
  // handle all state change
  const handleInputChange = (event) => {
    const name = event.target.name;
    setInputState({
      ...inputState,
      [name]: event.target.value,
    });

    //render
    if (name === "dataType") {
      updateChildShowData(event.target.value, inputState.unit);
    }else{
      updateChildShowData(inputState.dataType, event.target.value);
    }
  };

  const unitOptions = () => {
    if (props.drawerInput.displayLevel === "Activity") {
      if (props.drawerInput.projectStage === "Activities") {
        return (
          <>
            <option value={"Total Completion"}>Total Completion</option>
            <option value={"Worked hours"}>Worked hours</option>
            <option value={"Production rate"}>Production rate</option>
            <option value={"Production rate per crew"}>
              Production rate per crew
            </option>
          </>
        );
      } else {
        //Problem
        return (
          <>
            <option value={"Total Problem Hours"}>Total Problem Hours</option>
            <option value={"Problem Type-Hr"}>Problem Type-Hr</option>
            <option value={"Problem Time to Activity Time(Percentage)"}>
              Problem Time to Activity Time(Percentage)
            </option>
            <option value={"Problem Type-Total Hr"}>
              Problem Type-Total Hr
            </option>
            <option value={"Problem Type-Percentage"}>
              Problem Type-Percentage
            </option>
            <option
              value={"Problem-Activity Sequence/Time(Percentage-Relative)"}
            >
              Problem-Activity Sequence/Time(Percentage-Relative)
            </option>
            <option
              value={"Problem-Activity Sequence/Time(Percentage-Total Time)"}
            >
              Problem-Activity Sequence/Time(Percentage-Total Time)
            </option>
          </>
        );
      }
    } else if (props.drawerInput.displayLevel === "Category") {
      return (
        <>
          <option value={"Activity Sequence/Time(Percentage-Relative Time)"}>
            Activity Sequence/Time(Percentage-Relative Time)
          </option>
          <option value={"Activity Sequence/Time(Percentage-Total Time)"}>
            Activity Sequence/Time(Percentage-Total Time)
          </option>
          <option value={"Activity Time(Percentage-Relative Time)"}>
            Activity Time(Percentage-Relative Time)
          </option>
        </>
      );
    } else {
      //Project
      return (
        <>
          <option value={"Project Analysis-Sequences"}>
            Project Analysis-Sequences
          </option>
        </>
      );
    }
  };

  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          variant="fullWidth"
          value={navValue}
          onChange={handleNavChange}
          classes={{
            indicator: classes.indicator,
          }}
          style={{ width: "50%" }}
        >
          <LinkTab
            label="Tabular"
            href="/tabular"
            {...a11yProps(0)}
            className={
              navValue === 0 ? classes.selectedTab : classes.defaultTab
            }
          />
          <LinkTab
            label="Graphical"
            href="/graph"
            {...a11yProps(1)}
            className={
              navValue === 1 ? classes.selectedTab : classes.defaultTab
            }
            disabled={inputState.dataType==="All"}
          />
          <LinkTab
            label="Summary"
            href="/dis"
            {...a11yProps(2)}
            className={
              navValue === 2 ? classes.selectedTab : classes.defaultTab
            }
            disabled={inputState.dataType==="All"}
          />
        </Tabs>
        <FormControl className={classes.formControl}>
          <NativeSelect
            value={inputState.dataType}
            onChange={handleInputChange}
            inputProps={{
              name: "dataType",
            }}
            // disabled={
            //   props.drawerInput.displayLevel === "Project" ? true : false
            // }
          >
            <option aria-label="None" value={""}>
              Data Type
            </option>
            {props.drawerInput.displayLevel === "Project" ? (
              <option value={"Project"}>Project</option>
            ) : null}

            {props.drawerInput.displayLevel === "Category" ? (
              <>
                <option value={"Cabling"}>Cabling</option>
                <option value={"Civil"}>Civil</option>
                <option value={"Splicing"}>Splicing</option>
                <option value={"Testing"}>Testing</option>
                <option value={"Comments"}>Comments</option>
              </>
            ) : null}
            {props.drawerInput.displayLevel === "Activity" &&
            props.drawerInput.projectStage !== "" ? (
              <>
              {props.drawerInput.projectStage === "Activities" && navValue===0 ?  <option value={"All"}>All</option> :null}
               
                <option value={"Drilling/Conduit Placing"}>
                  Drilling/Conduit Placing
                </option>
                <option value={"FP/Drop Stubouts"}>FP/Drop Stubouts</option>
                <option value={"Hydrovac"}>Hydrovac</option>
                <option value={"Hand Pulling/Distribution Cable"}>
                  Hand Pulling/Distribution Cable
                </option>
                <option value={"Hand Pulling/Drops"}>Hand Pulling/Drops</option>
                <option value={"Hand Pulling/Terminal Tails"}>
                  Hand Pulling/Terminal Tails
                </option>
                <option value={"Jetting/Distribution Cable"}>
                  Jetting/Distribution Cable
                </option>
                <option value={"Jetting/Drops"}>Jetting/Drops</option>
                <option value={"Jetting/Terminal Tails"}>
                  Jetting/Terminal Tails
                </option>
                <option value={"OTDR"}>OTDR</option>
                <option value={"Power Meter"}>Power Meter</option>
                <option value={"Ruby Red"}>Ruby Red</option>
                <option value={"SB Placement"}>SB Placement</option>
                <option value={"Splicing"}>Splicing</option>
              </>
            ) : null}
          </NativeSelect>
        </FormControl>

        <FormControl className={classes.formControl}>
          <NativeSelect
            value={inputState.unit}
            onChange={handleInputChange}
            inputProps={{
              name: "unit",
            }}
            disabled={navValue === 1 ? false : true}
            style={{
              padding: "0px 2px",
            }}
          >
            <option aria-label="None" value={""}>
              Result Type
            </option>
            {unitOptions()}
          </NativeSelect>
        </FormControl>
      </AppBar>

      <TabPanel value={navValue} index={0} style={{ padding: "5px" }}>
        <EnhancedTable
          ref={tableRef}
          drawerInput={props.drawerInput}
          barInput={inputState}
          startDate={props.startDate}
          endDate={props.endDate}
          userDict={props.userDict}
        />
      </TabPanel>
      <TabPanel value={navValue} index={1}>
        <Graphical
          ref={graphRef}
          drawerInput={props.drawerInput}
          barInput={inputState}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </TabPanel>
      <TabPanel value={navValue} index={2}>
        <Summary
          ref={summaryRef}
          drawerInput={props.drawerInput}
          barInput={inputState}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </TabPanel>
    </div>
  );
};

ViewDataNav = forwardRef(ViewDataNav);
