import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyC2dsS6YyjqfLzqjhIhUcPIfJ5pPxOtIcg",
  authDomain: "jobsite-update-app---dev.firebaseapp.com",
  projectId: "jobsite-update-app---dev",
  storageBucket: "jobsite-update-app---dev.appspot.com",
  messagingSenderId: "530045812438",
  appId: "1:530045812438:web:e30deb0e6081f23dba9377",
  measurementId: "G-XQX1HF3GW2",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyA_Enbd9GpJCEreGE_rcLTKApXh6qsNIkg",
//   authDomain: "jobsite-update.firebaseapp.com",
//   projectId: "jobsite-update",
//   storageBucket: "jobsite-update.appspot.com",
//   messagingSenderId: "234599674455",
//   appId: "1:234599674455:web:c0a37302f006c42e35a912",
//   measurementId: "G-9JPE0W0EC7"
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// same as APP
export const Firestore = {
  User:{
    GetUserName:(userUID)=>{
      return firebase
        .firestore()
        .collection("users")
        .doc(userUID)
        .get();
    },
    GetAllUser:()=>{
      return firebase
        .firestore()
        .collection("users")
        .get();
    }
  },
  Company: {
    /**
     * Get all Company that has the user
     * @param userUID User ID that belongs to a company
     * @returns
     */
    GetAllCompanyByUserRef: (userUID) => {
      return firebase
        .firestore()
        .collection("companies")
        .where("users", "array-contains", userUID)
        .get();
    },
  },
  Project: {
    /**
     * Gets all projects within this company the user has access to
     * @param companyUID
     * @param userUID
     * @returns
     */
    GetAll: (companyUID, userUID) => {
      return firebase
        .firestore()
        .collection("companies")
        .doc(companyUID)
        .collection("company-projects")
        .where(`projectUsers.${userUID}`, "!=", "")
        .get()
        .then((querySnapShot) => {
          const getProjectsPromise = querySnapShot.docs.map((doc) =>
            firebase
              .firestore()
              .collection("projects")
              .doc(doc.data().projectID)
              .get()
          );
          return Promise.all(getProjectsPromise).then((documentSnapShot) => {
            return documentSnapShot.map((document) => {
              return { ...document.data(), id: document.id };
            });
          });
        });
    },
    /**
     * Gets projects name by id
     * @param companyUID
     * @returns
     */
    GetProjectById: (pid) => {
      return firebase.firestore().collection("projects").doc(pid).get();
    },
  },
  Activity: {
    GetAllActivity: (projectId, startDate, endDate) => {
      return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .where("dateTime", ">=", startDate)
        .where("dateTime", "<=", endDate)
        .orderBy("dateTime")
        .get();
    },
    // Category level

    GetCategoryData:(projectId,startDate,endDate,category)=>{
      return firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection("activities")
      .where("category", "==", category)
      .where("dateTime",">=",startDate)
      .where("dateTime","<=",endDate)
      .orderBy("dateTime")
      .get();
    },
    //Activity level
    GetDataActivityLevel:(projectId,startDate,endDate,activityType,subActivityType)=>{
      if(activityType==="Hand Pulling" || activityType==="Jetting"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .where("activityType", "==", activityType)
        .where("subActivityType", "==", subActivityType)
        .where("dateTime",">=",startDate)
        .where("dateTime","<=",endDate)
        .orderBy("dateTime")
        .get(); 
      } else if(activityType==="Splicing"){
        return firebase

        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .where("category", "==", "Splicing")
        .where("dateTime",">=",startDate)
        .where("dateTime","<=",endDate)
        .orderBy("dateTime")
        .get(); 
      }else{
        return firebase

        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .where("activityType", "==", activityType)
        .where("dateTime",">=",startDate)
        .where("dateTime","<=",endDate)
        .orderBy("dateTime")
        .get();
      }
    },
  },
  Problem: {
    GetAllProblem: (projectId, startDate, endDate) => {
      return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("problems")
        .where("dateTime", ">=", startDate)
        .where("dateTime", "<=", endDate)
        .orderBy("dateTime")
        .get();
    },
    GetActivityProblem: (projectId,startDate,endDate,activityType,subActivityType) => {
      if(activityType==="Hand Pulling" || activityType==="Jetting"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("problems")
        .where("activityType", "==", activityType)
        .where("subActivityType", "==", subActivityType)
        .where("dateTime",">=",startDate)
        .where("dateTime","<=",endDate)
        .orderBy("dateTime")
        .orderBy("issueType")
        .get(); 
      } else if(activityType==="Splicing"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("problems")
        .where("category", "==", "Splicing")
        .where("dateTime",">=",startDate)
        .where("dateTime","<=",endDate)
        .orderBy("dateTime")
        .orderBy("issueType")
        .get(); 
      }else{
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("problems")
        .where("activityType", "==", activityType)
        .where("dateTime",">=",startDate)
        .where("dateTime","<=",endDate)
        .orderBy("dateTime")
        .orderBy("issueType")
        .get();
      }
    },
    GetCategoryProblem:(projectId,startDate,endDate,category) => {
      return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("problems")
        .where("category", "==", category)
        .where("dateTime",">=",startDate)
        .where("dateTime","<=",endDate)
        .orderBy("dateTime")
        .get();
    },
    // GetAllCivil: (projectId,startDate,endDate) => {
    //   return firebase
    //     .firestore()
    //     .collection("projects")
    //     .doc(projectId)
    //     .collection("problems")
    //     .where("category", "==", "Civil")
    //     .where("dateTime",">=",startDate)
    //     .where("dateTime","<=",endDate)
    //     .orderBy("dateTime")
    //     .get();
    // },
    // GetAllCabling: (projectId,startDate,endDate) => {
    //   return firebase
    //     .firestore()
    //     .collection("projects")
    //     .doc(projectId)
    //     .collection("problems")
    //     .where("category", "==", "Cabling")
    //     .where("dateTime",">=",startDate)
    //     .where("dateTime","<=",endDate)
    //     .orderBy("dateTime")
    //     .get();
    // },
    // GetAllSplicing: (projectId,startDate,endDate) => {
    //   return firebase
    //     .firestore()
    //     .collection("projects")
    //     .doc(projectId)
    //     .collection("problems")
    //     .where("category", "==", "Splicing")
    //     .where("dateTime",">=",startDate)
    //     .where("dateTime","<=",endDate)
    //     .orderBy("dateTime")
    //     .get();
    // },
    // GetAllTesting: (projectId,startDate,endDate) => {
    //   return firebase
    //     .firestore()
    //     .collection("projects")
    //     .doc(projectId)
    //     .collection("problems")
    //     .where("category", "==", "Testing")
    //     .where("dateTime",">=",startDate)
    //     .where("dateTime","<=",endDate)
    //     .orderBy("dateTime")
    //     .get();
    // },
  },

  /**only edit data on activity level */
  Edit:{
    updateActivity: (projectId,activityId,data,activityType) => {
      if(activityType==="Drilling/Conduit Placing"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          length: data.completion,
          unit: data.unit,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="FP/Drop Stubouts"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          quantity:data.quantity,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Hydrovac"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          numPotholes:data.numPotholes,
          numTrips:data.numTrips,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Hand Pulling/Distribution Cable"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          length:data.length,
          unit:data.unit,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Hand Pulling/Drops"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          dropCount:data.dropCount,
          dropLength:data.dropLength,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Hand Pulling/Terminal Tails"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          metersPlaced:data.metersPlaced,
          numTerminals:data.numTerminals,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Jetting/Distribution Cable"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          length:data.length,
          unit:data.unit,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Jetting/Drops"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          dropLength:data.dropLength,
          dropCount:data.dropCount,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Jetting/Terminal Tails"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          metersPlaced:data.metersPlaced,
          numTerminals:data.numTerminals,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="OTDR"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          numFibers:data.numFibers,
          numTesters:data.numTesters,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Power Meter"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          numFibers:data.numFibers,
          numTerminals:data.numFibers,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Ruby Red"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          numFibersPL:data.numFibersPL,
          numTesters:data.numTesters,
          testersFHD:data.testersFHD,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="SB Placement"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          numUnits:data.numUnits,
          type:data.type,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }else if(activityType==="Splicing"){
        return firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("activities")
        .doc(activityId)
        .update({
          numWL:data.numWL,
          lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
          numCrew:data.numCrew,
          numHours:data.numHours,
        })
      }
    },
    updateProblem:(projectId,problemId,data) => {
      return firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection("problems")
      .doc(problemId)
      .update({
        lastUpdated:firebase.firestore.Timestamp.fromDate(new Date()),
        numHours:data.numHours,
        issueType:data.problemType
      })
    },
    deleteActivity: (projectId,activityId) => {
      return firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection("activities")
      .doc(activityId)
      .delete()
    },
    deleteProblem: (projectId,problemId) => {
      return firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection("problems")
      .doc(problemId)
      .delete()
    },
  }
};
