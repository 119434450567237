import React, { useState, useRef } from "react";
import {
  AppBar,
  Tabs,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import firebase from "firebase";

import { Home } from "./screens/Home.jsx";
import { About } from "./screens/About.jsx";
import { Launch } from "./screens/Launch.jsx";
import { ViewData } from "./screens/ViewData.jsx";
import { Contact } from "./screens/Contact.jsx";
import { Firestore } from "./firebase/firestore";
import { LinkTab } from "./component/LinkTab";
import { TabPanel } from "./component/TabPanel";
import { styles } from "./style/style";

function a11yProps(index) {
  return {
    key: index,
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export default function NavTabs() {
  const classes = styles();
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(true);

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");
  const navbar = useRef(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleInputChange = (event) => {
    const name = event.target.name;

    if (name === "userEmail") {
      setUserEmail(event.target.value);
    }
    if (name === "password") {
      setPassword(event.target.value);
    }
  };

  const handleLogin = () => {
    console.log(userEmail, password);
    firebase
      .auth()
      .signInWithEmailAndPassword(userEmail, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user.uid);
        setUserId(user.uid);
        setOpen(false);
      })
      .catch((error) => {
        alert("Incorrect Email or Password");
        console.log("error login");
      });
  };

  const handleLogout = () => {
    // console.log(userEmail, password);
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Signed out
        setUserId("");
        setTabValue(0);
        setOpen(true);
        // reset
        setUserEmail("");
        setPassword("");
      })
      .catch((error) => {
        console.log("error logout");
      });
  };

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar} ref={navbar}>
        <Tabs
          variant="fullWidth"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="nav tabs example"
          classes={{
            indicator: classes.indicator,
          }}
          style={{ width: "80%" }}
        >
          <LinkTab
            label="Home"
            href="/home"
            {...a11yProps(0)}
            className={
              tabValue === 0 ? classes.selectedTab : classes.defaultTab
            }
          />
          <LinkTab
            label="About"
            href="/about"
            {...a11yProps(1)}
            className={
              tabValue === 1 ? classes.selectedTab : classes.defaultTab
            }
          />
          <LinkTab
            label="Launch Web App"
            href="/launch"
            {...a11yProps(2)}
            className={
              tabValue === 2 ? classes.selectedTab : classes.defaultTab
            }
          />
          <LinkTab
            label="View Data"
            href="/viewData"
            {...a11yProps(3)}
            className={
              tabValue === 3 ? classes.selectedTab : classes.defaultTab
            }
          />
          <LinkTab
            label="Contact Us"
            href="/contact"
            {...a11yProps(4)}
            className={
              tabValue === 4 ? classes.selectedTab : classes.defaultTab
            }
          />
        </Tabs>

        <Button
          variant="contained"
          className={classes.logoutButton}
          onClick={handleLogout}
          color="secondary"
          title="Logout"
        >
          Log Out
        </Button>
      </AppBar>

      <TabPanel value={tabValue} index={0} className="tabPanel">
        <Home />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className="tabPanel">
        <About />
      </TabPanel>
      <TabPanel value={tabValue} index={2} className="tabPanel">
        <Launch />
      </TabPanel>
      <TabPanel value={tabValue} index={3} className="tabPanel">
        <ViewData
          uid={userId}
          padding={navbar.current ? navbar.current.clientHeight : null}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4} className="tabPanel">
        <Contact />
      </TabPanel>

      {/* Login */}
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            name="userEmail"
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleInputChange}
          />

          <TextField
            required
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
