import React, { useState, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@material-ui/core";
import firebase from "firebase";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Firestore } from "../firebase/firestore";
import { headCellValues, Unit, issues, Check, SBType } from "../data/const";
import {
  reformDate,
  calRate,
  calRatePerCrew,
  getTypeSum,
  getTypeDailyAvg,
  getTotalDay,
  getSum,
  getProjectData,
  isObjectValueEqual,
  toDecimal2,
  getActivityType,
} from "../data/process";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props.numSelected;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.userInput === "" ? "Select Activity" : props.userInput}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export let EnhancedTable = (props, ref) => {
  useImperativeHandle(ref, () => ({
    showData: (dataType, unit) => {
      handleGetData(dataType, unit);
    },
  }));
  const classes = useStyles();
  const pid = props.drawerInput.project;
  //const cid = props.drawerInput.company;
  const userDict = props.userDict;
  let displayLevelInput = props.drawerInput.displayLevel;
  let projectStageInput = props.drawerInput.projectStage;
  let dataTypeInput = props.drawerInput.dataType;
  let unitInput = props.drawerInput.unit;
  const viewStartDate = new Date(props.startDate);
  const viewEndDate = new Date(props.endDate + "T23:59:59");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [acData, setAcData] = useState();
  const [headCells, setHeadCells] = useState([]);
  const [emptyRows, setEmptyRows] = useState(0);

  const [editDialog, setEditDialog] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [editObject, setEditObject] = useState();

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(-1);

  const [showNoData, setShowNoData] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && acData !== null) {
      const newSelecteds = acData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleGetData = (dataType, unit) => {
    console.log("table", dataType);
    if (dataType !== "") {
      if (displayLevelInput === "Activity") {
        if (projectStageInput === "Activities") {
          if (dataType === "All") {
            setHeadCells(headCellValues.ActivityStage.Activity.All);
            Firestore.Activity.GetAllActivity(
              pid,
              viewStartDate,
              viewEndDate
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  let dropLength = null;
                  let activityValue = null;
                  if (doc.data().subActivityType === "Drops") {
                    dropLength = parseFloat(
                      doc
                        .data()
                        .dropLength.slice(0, doc.data().dropLength.length - 1)
                    );
                  }
                  if (doc.data().activityType === "Drilling / Conduit Placement") {
                    if (doc.data().unit === "Inch") {
                      activityValue = doc.data().length * 0.0254;
                    } else if (doc.data().unit === "Feet") {
                      activityValue = doc.data().length * 0.3048;
                    } else {
                      activityValue = doc.data().length;
                    }
                  } else if (doc.data().activityType === "FD / Drop Stubouts") {
                    activityValue = doc.data().quantity;
                  } else if (doc.data().activityType === "Hydrovac") {
                    activityValue = doc.data().numPotholes;
                  } else if (doc.data().activityType === "Hand Pulling") {
                    if (doc.data().subActivityType === "Distribution Cable") {
                      activityValue = doc.data().length;
                    } else if (doc.data().subActivityType === "Drops") {
                      activityValue = parseFloat(
                        doc
                          .data()
                          .dropLength.slice(0, doc.data().dropLength.length - 1)
                      );
                    } else {
                      activityValue = doc.data().metersPlaced;
                    }
                  } else if (doc.data().activityType === "Jetting") {
                    if (doc.data().subActivityType === "Distribution Cable") {
                      activityValue = doc.data().length;
                    } else if (doc.data().subActivityType === "Drops") {
                      activityValue = parseFloat(
                        doc
                          .data()
                          .dropLength.slice(0, doc.data().dropLength.length - 1)
                      );
                    } else {
                      activityValue = doc.data().metersPlaced;
                    }
                  } else if (doc.data().activityType === "OTDR") {
                    activityValue = doc.data().numFibers;
                  } else if (doc.data().activityType === "Power Meter") {
                    activityValue = doc.data().numFibers;
                  } else if (doc.data().activityType === "Ruby Red") {
                    activityValue = doc.data().numFibersPL;
                  } else if (doc.data().category === "Splicing") {
                    activityValue = doc.data().numWL;
                  }
                  let rate = toDecimal2(calRate(activityValue, doc.data().numHours))
                  let ratePerCrew =toDecimal2(calRatePerCrew(
                    calRate(activityValue, doc.data().numHours),
                    doc.data().numCrew))
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    length: toDecimal2(doc.data().length),
                    unit: doc.data().unit,
                    quantity: doc.data().quantity,
                    numPotholes: doc.data().numPotholes,
                    numTrips: doc.data().numTrips,
                    dropCount: doc.data().dropCount,
                    dropLength: dropLength,
                    metersPlaced: doc.data().metersPlaced,
                    numTerminals: doc.data().numTerminals,
                    numFibers: doc.data().numFibers,
                    numTesters: doc.data().numTesters,
                    numFibersPL: doc.data().numFibersPL,
                    testersFHD: doc.data().testersFHD,
                    numUnits: doc.data().numUnits,
                    type: doc.data().type,
                    numWL: doc.data().numWL,
                    rate: rate,
                    ratePerCrew: ratePerCrew,
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          }
          if (dataType === "Drilling/Conduit Placing") {
            setHeadCells(headCellValues.ActivityStage.Activity.Drilling);
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Drilling / Conduit Placement",
              null
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    completion: toDecimal2(doc.data().length),
                    unit: doc.data().unit,
                    rate: toDecimal2(
                      calRate(doc.data().length, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().length, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "FP/Drop Stubouts") {
            setHeadCells(headCellValues.ActivityStage.Activity.FP);
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "FD / Drop Stubouts",
              null
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    quantity: toDecimal2(doc.data().quantity),
                    rate: toDecimal2(
                      calRate(doc.data().quantity, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().quantity, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Hydrovac") {
            setHeadCells(headCellValues.ActivityStage.Activity.Hydrovac);
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Hydrovac",
              null
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    numPotholes: doc.data().numPotholes,
                    numTrips: doc.data().numTrips,
                    rate: toDecimal2(
                      calRate(doc.data().numPotholes, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().numPotholes, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Hand Pulling/Distribution Cable") {
            setHeadCells(
              headCellValues.ActivityStage.Activity.HandPullingDistributionCable
            );
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Hand Pulling",
              "Distribution Cable"
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    length: toDecimal2(doc.data().length),
                    unit: doc.data().unit,
                    rate: toDecimal2(
                      calRate(doc.data().length, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().length, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Hand Pulling/Drops") {
            setHeadCells(
              headCellValues.ActivityStage.Activity.HandPullingDrops
            );
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Hand Pulling",
              "Drops"
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  let dropLength = parseFloat(
                    doc
                      .data()
                      .dropLength.slice(0, doc.data().dropLength.length - 1)
                  );
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    dropCount: doc.data().dropCount,
                    dropLength: toDecimal2(dropLength),
                    rate: toDecimal2(calRate(dropLength, doc.data().numHours)),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(dropLength, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Hand Pulling/Terminal Tails") {
            setHeadCells(
              headCellValues.ActivityStage.Activity.HandPullingTerminalTails
            );
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Hand Pulling",
              "Terminal Tails"
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    metersPlaced: toDecimal2(doc.data().metersPlaced),
                    numTerminals: doc.data().numTerminals,
                    rate: toDecimal2(
                      calRate(doc.data().metersPlaced, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().metersPlaced, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Jetting/Distribution Cable") {
            setHeadCells(
              headCellValues.ActivityStage.Activity.JettingDistributionCable
            );
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Jetting",
              "Distribution Cable"
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    length: toDecimal2(doc.data().length),
                    unit: doc.data().unit,
                    rate: toDecimal2(
                      calRate(doc.data().length, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().length, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Jetting/Drops") {
            setHeadCells(headCellValues.ActivityStage.Activity.JettingDrops);
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Jetting",
              "Drops"
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  let dropLength = parseFloat(
                    doc
                      .data()
                      .dropLength.slice(0, doc.data().dropLength.length - 1)
                  );
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: doc.data().numHours,
                    dropLength: toDecimal2(dropLength),
                    dropCount: doc.data().dropCount,
                    rate: toDecimal2(calRate(dropLength, doc.data().numHours)),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(dropLength, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Jetting/Terminal Tails") {
            setHeadCells(
              headCellValues.ActivityStage.Activity.JettingTerminalTails
            );
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Jetting",
              "Terminal Tails"
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    metersPlaced: toDecimal2(doc.data().metersPlaced),
                    numTerminals: doc.data().numTerminals,
                    rate: toDecimal2(
                      calRate(doc.data().metersPlaced, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().metersPlaced, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "OTDR") {
            setHeadCells(headCellValues.ActivityStage.Activity.OTDR);
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "OTDR",
              null
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    numFibers: doc.data().numFibers,
                    numTesters: doc.data().numTesters,
                    rate: toDecimal2(
                      calRate(doc.data().numFibers, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().numFibers, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Power Meter") {
            setHeadCells(headCellValues.ActivityStage.Activity.PowerMeter);
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Power Meter",
              null
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    numFibers: doc.data().numFibers,
                    numTerminals: doc.data().numTerminals,
                    rate: toDecimal2(
                      calRate(doc.data().numFibers, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().numFibers, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Ruby Red") {
            setHeadCells(headCellValues.ActivityStage.Activity.RubyRed);
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Ruby Red",
              null
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  let testersFHDValue = null;
                  if (doc.data().testersFHD) {
                    testersFHDValue = "true";
                  } else {
                    testersFHDValue = "false";
                  }
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    numFibersPL: doc.data().numFibersPL,
                    numTesters: doc.data().numTesters,
                    testersFHD: testersFHDValue,
                    rate: toDecimal2(
                      calRate(doc.data().numFibersPL, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().numFibersPL, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "SB Placement") {
            setHeadCells(headCellValues.ActivityStage.Activity.SBPlacement);
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "SB Placement",
              null
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    numUnits: doc.data().numUnits,
                    type: doc.data().type,
                    rate: toDecimal2(
                      calRate(doc.data().numUnits, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().numUnits, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          } else if (dataType === "Splicing") {
            setHeadCells(headCellValues.ActivityStage.Activity.Splicing);
            Firestore.Activity.GetDataActivityLevel(
              pid,
              viewStartDate,
              viewEndDate,
              "Splicing",
              null
            ).then((snapshot) => {
              if (snapshot.size !== 0) {
                setShowNoData(false)
                let idInitial = 0;
                let data = snapshot.docs.map((doc) => {
                  return {
                    id: idInitial++,
                    activityId: doc.id,
                    user: userDict[doc.data().userUID],
                    dateEntry: reformDate(doc.data().dateTime.toDate()),
                    dateActivity: reformDate(doc.data().dateTime.toDate()),
                    catagory: doc.data().category,
                    activity: doc.data().activityType,
                    crewID: 0,
                    numCrew: doc.data().numCrew,
                    numHours: toDecimal2(doc.data().numHours),
                    numWL: doc.data().numWL,
                    rate: toDecimal2(
                      calRate(doc.data().numWL, doc.data().numHours)
                    ),
                    ratePerCrew: toDecimal2(
                      calRatePerCrew(
                        calRate(doc.data().numWL, doc.data().numHours),
                        doc.data().numCrew
                      )
                    ),
                  };
                });
                setAcData(data);
              } else {
                setShowNoData(true);
              }
              setEmptyRows(
                rowsPerPage -
                  Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
              );
            });
          }
        } else {
          //Problem Stage
          setHeadCells(headCellValues.ProblemStage.Activity);
          let activityType = getActivityType(dataType).ac;
          let subActivityType = getActivityType(dataType).sub;
          Firestore.Problem.GetActivityProblem(
            pid,
            viewStartDate,
            viewEndDate,
            activityType,
            subActivityType
          ).then((snapshot) => {
            if (snapshot.size !== 0) {
              setShowNoData(false)
              let idInitial = 0;
              let data = snapshot.docs.map((doc) => {
                return {
                  id: idInitial++,
                  problemId: doc.id,
                  user: userDict[doc.data().userUID],
                  dateEntry: reformDate(doc.data().dateTime.toDate()),
                  dateActivity: reformDate(doc.data().dateTime.toDate()),
                  catagory: doc.data().category,
                  activity: doc.data().activityType,
                  numHours: toDecimal2(doc.data().numHours),
                  problemType: doc.data().issueType,
                  //TODO 
                  comments: doc.data().issueMessage,
                };
              });
              setAcData(data);
            } else {
              setShowNoData(true);
            }
            setEmptyRows(
              rowsPerPage -
                Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
            );
          });
        }
      } else if (displayLevelInput === "Category") {
        setHeadCells(headCellValues.ActivityStage.Category);
        Firestore.Activity.GetCategoryData(
          pid,
          viewStartDate,
          viewEndDate,
          dataType
        ).then((snapshot) => {
          if (snapshot.size !== 0) {
            setShowNoData(false)
            let activityValue = 0;
            let rawData = snapshot.docs.map((doc) => {
              let subActivityType = null;
              if (dataType === "Civil") {
                if (
                  doc.data().activityType === "Drilling / Conduit Placement"
                ) {
                  if (doc.data().unit === "Inch") {
                    activityValue = doc.data().length * 0.0254;
                  } else if (doc.data().unit === "Feet") {
                    activityValue = doc.data().length * 0.3048;
                  } else {
                    activityValue = doc.data().length;
                  }
                } else if (doc.data().activityType === "FD / Drop Stubouts") {
                  activityValue = doc.data().quantity;
                } else if (doc.data().activityType === "Hydrovac") {
                  activityValue = doc.data().numPotholes;
                } else {
                  activityValue = doc.data().numUnits;
                }
              } else if (dataType === "Cabling") {
                if (doc.data().activityType === "Hand Pulling") {
                  if (doc.data().subActivityType === "Distribution Cable") {
                    activityValue = doc.data().length;
                    subActivityType = doc.data().subActivityType;
                  } else if (doc.data().subActivityType === "Drops") {
                    activityValue = parseFloat(
                      doc
                        .data()
                        .dropLength.slice(0, doc.data().dropLength.length - 1)
                    );
                    subActivityType = doc.data().subActivityType;
                  } else {
                    activityValue = doc.data().metersPlaced;
                    subActivityType = doc.data().subActivityType;
                  }
                } else if (doc.data().activityType === "Jetting") {
                  if (doc.data().subActivityType === "Distribution Cable") {
                    activityValue = doc.data().length;
                    subActivityType = doc.data().subActivityType;
                  } else if (doc.data().subActivityType === "Drops") {
                    activityValue = parseFloat(
                      doc
                        .data()
                        .dropLength.slice(0, doc.data().dropLength.length - 1)
                    );
                    subActivityType = doc.data().subActivityType;
                  } else {
                    activityValue = doc.data().metersPlaced;
                    subActivityType = doc.data().subActivityType;
                  }
                }
              } else if (dataType === "Testing") {
                if (doc.data().activityType === "OTDR") {
                  activityValue = doc.data().numFibers;
                } else if (doc.data().activityType === "Power Meter") {
                  activityValue = doc.data().numFibers;
                } else {
                  activityValue = doc.data().numFibersPL;
                }
              } else if (dataType === "Splicing") {
                activityValue = doc.data().numWL;
              }
              return {
                activityType: doc.data().activityType,
                subActivityType: subActivityType,
                category: doc.data().category,
                numHours: doc.data().numHours,
                value: activityValue,
                date: doc.data().dateTime.toDate(),
              };
            });
            let res = getTypeSum(rawData, false);
            let daily = getTypeDailyAvg(rawData);
            let data = [];
            for (let i = 0; i < res.value.length; i++) {
              data.push({
                //TODO change the planned value
                id: i,
                activity: res.name[i],
                planned: null,
                completed: toDecimal2(res.value[i]),
                totalTime: toDecimal2(res.hour[i]),
                plannedTime: null,
                bp: null,
                dailyRate: toDecimal2(daily[i]),
                planRate: null,
                bpRate: null,
                relativeTime: toDecimal2(
                  (res.hour[i] / getSum(res.hour)) * 100
                ),
                //relativeTotalCategoryTime:null
              });
            }
            setAcData(data);
          } else {
            setShowNoData(true);
          }
          setEmptyRows(
            rowsPerPage -
              Math.min(rowsPerPage, snapshot.size - page * rowsPerPage)
          );
        });
      } else {
        //Project level
        setHeadCells(headCellValues.ActivityStage.Project);
        //Activity Data
        Firestore.Activity.GetAllActivity(pid, viewStartDate, viewEndDate).then(
          (snapshot) => {
            if (snapshot.size > 0) {
              let activityData = snapshot.docs.map((doc) => {
                return {
                  category: doc.data().category,
                  numHours: doc.data().numHours,
                  dateTime: doc.data().dateTime,
                };
              });
              //Problem Data
              Firestore.Problem.GetAllProblem(
                pid,
                viewStartDate,
                viewEndDate
              ).then((snapshot) => {
                let problemData = null
                if (snapshot.size > 0) {
                   problemData = snapshot.docs.map((doc) => {
                    return {
                      category: doc.data().category,
                      numHours: doc.data().numHours,
                      dateTime: doc.data().dateTime,
                    };
                  });
                }
                setAcData(getProjectData(activityData, problemData));
              });
            } else {
              setShowNoData(true);
            }
          }
        );
      }
    }
  };
  const handleEdit = (id) => {
    setEditDialog(true);
    setEditIndex(id);
    setEditObject(acData[id]);
  };
  const handleClose = () => {
    setEditDialog(false);
    setEditIndex(-1);
    setDeleteId(-1);
  };
  const handleSave = () => {
    setEditDialog(false);
    if (projectStageInput === "Activities") {
      Firestore.Edit.updateActivity(
        pid,
        editObject.activityId,
        editObject,
        props.barInput.dataType
      ).then(() => {
        console.log("Document successfully updated!");
        handleGetData(props.barInput.dataType, unitInput);
        setEditIndex(-1);
      });
    } else {
      Firestore.Edit.updateProblem(pid, editObject.problemId, editObject).then(
        () => {
          console.log("Document successfully updated!");
          handleGetData(props.barInput.dataType, unitInput);
          setEditIndex(-1);
        }
      );
    }
  };

  const handleDelete = (id) => {
    setDeleteDialog(true);
    setDeleteId(id);
  };

  const handleDeleteClose = () => {
    setDeleteDialog(false);
    setDeleteId(-1);
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(false);
    if (projectStageInput === "Activities") {
      Firestore.Edit.deleteActivity(pid, deleteId).then(() => {
        console.log("Document successfully deleted!");
        handleGetData(dataTypeInput, unitInput);
        setDeleteId(-1);
      });
    } else {
      //
      Firestore.Edit.deleteProblem(pid, deleteId).then(() => {
        console.log("Document successfully deleted!");
        handleGetData(dataTypeInput, unitInput);
        setDeleteId(-1);
      });
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const TableCells = (row) => {
    if (displayLevelInput === "Activity") {
      if (projectStageInput === "Activities") {
        const tableCellsCommonFront = (
          <>
            <TableCell align="left">{row.dateEntry}</TableCell>
            <TableCell align="left">{row.dateActivity}</TableCell>
            <TableCell align="left">{row.catagory}</TableCell>
            <TableCell align="left">{row.activity}</TableCell>
            <TableCell align="left">{row.crewID}</TableCell>
            <TableCell align="left">{row.numCrew}</TableCell>
            <TableCell align="left">{row.numHours}</TableCell>
          </>
        );

        const tableCellsCommonBack = (
          <>
            <TableCell align="left">{row.rate}</TableCell>
            <TableCell align="left">{row.ratePerCrew}</TableCell>
          </>
        );
        if (props.barInput.dataType === "All") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.length}</TableCell>
              <TableCell align="left">{row.unit}</TableCell>
              <TableCell align="left">{row.quantity}</TableCell>
              <TableCell align="left">{row.numPotholes}</TableCell>
              <TableCell align="left">{row.numTrips}</TableCell>
              <TableCell align="left">{row.dropCount}</TableCell>
              <TableCell align="left">{row.dropLength}</TableCell>
              <TableCell align="left">{row.metersPlaced}</TableCell>
              <TableCell align="left">{row.numTerminals}</TableCell>
              <TableCell align="left">{row.numFibers}</TableCell>
              <TableCell align="left">{row.numTesters}</TableCell>
              <TableCell align="left">{row.numFibersPL}</TableCell>
              <TableCell align="left">{row.testersFHD}</TableCell>
              <TableCell align="left">{row.numUnits}</TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{row.numWL}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Drilling/Conduit Placing") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.completion}</TableCell>
              <TableCell align="left">{row.unit}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "FP/Drop Stubouts") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.quantity}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Hydrovac") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.numPotholes}</TableCell>
              <TableCell align="left">{row.numTrips}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (
          props.barInput.dataType === "Hand Pulling/Distribution Cable"
        ) {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.length}</TableCell>
              <TableCell align="left">{row.unit}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Hand Pulling/Drops") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.dropCount}</TableCell>
              <TableCell align="left">{row.dropLength}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Hand Pulling/Terminal Tails") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.metersPlaced}</TableCell>
              <TableCell align="left">{row.numTerminals}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Jetting/Distribution Cable") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.length}</TableCell>
              <TableCell align="left">{row.unit}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Jetting/Drops") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.dropCount}</TableCell>
              <TableCell align="left">{row.dropLength}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Jetting/Terminal Tails") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.metersPlaced}</TableCell>
              <TableCell align="left">{row.numTerminals}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "OTDR") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.numFibers}</TableCell>
              <TableCell align="left">{row.numTesters}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Power Meter") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.numFibers}</TableCell>
              <TableCell align="left">{row.numTerminals}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Ruby Red") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.numFibersPL}</TableCell>
              <TableCell align="left">{row.numTesters}</TableCell>
              <TableCell align="left">{row.testersFHD}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "SB Placement") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.numUnits}</TableCell>
              <TableCell align="left">{row.type}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        } else if (props.barInput.dataType === "Splicing") {
          return (
            <>
              {tableCellsCommonFront}
              <TableCell align="left">{row.numWL}</TableCell>
              {tableCellsCommonBack}
            </>
          );
        }
      } else {
        //Problem
        return (
          <>
            <TableCell align="left">{row.dateEntry}</TableCell>
            <TableCell align="left">{row.dateActivity}</TableCell>
            <TableCell align="left">{row.catagory}</TableCell>
            <TableCell align="left">{row.activity}</TableCell>
            <TableCell align="left">{row.numHours}</TableCell>
            <TableCell align="left">{row.problemType}</TableCell>
            <TableCell align="left">{row.comments}</TableCell>
          </>
        );
      }
    } else if (displayLevelInput === "Category") {
      return (
        <>
          <TableCell align="left">{row.activity}</TableCell>
          <TableCell align="left">{row.completed}</TableCell>
          <TableCell align="left">{row.planned}</TableCell>
          <TableCell align="left">{row.totalTime}</TableCell>
          <TableCell align="left">{row.plannedTime}</TableCell>
          <TableCell align="left">{row.bp}</TableCell>
          <TableCell align="left">{row.dailyRate}</TableCell>
          <TableCell align="left">{row.planRate}</TableCell>
          <TableCell align="left">{row.bpRate}</TableCell>
          <TableCell align="left">{row.relativeTime}</TableCell>
          {/* <TableCell align="left">{row.relativeTotalCategoryTime}</TableCell> */}
        </>
      );
    } else {
      //Project
      return (
        <>
          <TableCell align="left">{row.category}</TableCell>
          <TableCell align="left">{row.totalHour}</TableCell>
          <TableCell align="left">{row.totalDay}</TableCell>
          <TableCell align="left">{row.plannedHour}</TableCell>
          <TableCell align="left">{row.plannedDay}</TableCell>
          <TableCell align="left">{row.bp}</TableCell>
          <TableCell align="left">{row.relativeActivity}</TableCell>
          <TableCell align="left">{row.relativeTotal}</TableCell>
          <TableCell align="left">{row.problemHour}</TableCell>
          <TableCell align="left">{row.problemDay}</TableCell>
          <TableCell align="left">{row.problemPercentage}</TableCell>
        </>
      );
    }
  };

  //Edit row
  const handleEditChange = (event) => {
    var inputValue = event.target.value;
    if (event.target.id === "numCrew") {
      setEditObject({
        ...editObject,
        numCrew: parseFloat(inputValue),
        ratePerCrew: calRatePerCrew(
          calRate(editObject.completion, editObject.numHours),
          parseFloat(inputValue)
        ),
      });
    } else if (event.target.id === "numHours") {
      setEditObject({
        ...editObject,
        numHours: parseFloat(inputValue),
        rate: calRate(editObject.completion, inputValue),
        ratePerCrew: calRatePerCrew(
          calRate(editObject.completion, inputValue),
          editObject.numCrew
        ),
      });
    } else if (event.target.id === "completion") {
      setEditObject({
        ...editObject,
        completion: parseFloat(inputValue),
        rate: calRate(inputValue, editObject.numHours),
        ratePerCrew: calRatePerCrew(
          calRate(inputValue, editObject.numHours),
          editObject.numCrew
        ),
      });
    } else if (event.target.id === "unit") {
      setEditObject({
        ...editObject,
        unit: inputValue,
      });
    } else if (event.target.id === "quantity") {
      setEditObject({
        ...editObject,
        quantity: parseFloat(inputValue),
      });
    } else if (event.target.id === "numPotholes") {
      setEditObject({
        ...editObject,
        numPotholes: parseFloat(inputValue),
      });
    } else if (event.target.id === "numTrips") {
      setEditObject({
        ...editObject,
        numTrips: parseFloat(inputValue),
      });
    } else if (event.target.id === "length") {
      setEditObject({
        ...editObject,
        length: parseFloat(inputValue),
      });
    } else if (event.target.id === "unit") {
      setEditObject({
        ...editObject,
        unit: inputValue,
      });
    } else if (event.target.id === "dropCount") {
      setEditObject({
        ...editObject,
        dropCount: parseFloat(inputValue),
      });
    } else if (event.target.id === "dropLength") {
      setEditObject({
        ...editObject,
        dropLength: inputValue + "m",
      });
    } else if (event.target.id === "metersPlaced") {
      setEditObject({
        ...editObject,
        metersPlaced: parseFloat(inputValue),
      });
    } else if (event.target.id === "numTerminals") {
      setEditObject({
        ...editObject,
        numTerminals: parseFloat(inputValue),
      });
    } else if (event.target.id === "numFibers") {
      setEditObject({
        ...editObject,
        numFibers: parseFloat(inputValue),
      });
    } else if (event.target.id === "numTesters") {
      setEditObject({
        ...editObject,
        numTesters: parseFloat(inputValue),
      });
    } else if (event.target.id === "numTerminals") {
      setEditObject({
        ...editObject,
        numTerminals: parseFloat(inputValue),
      });
    } else if (event.target.id === "numFibersPL") {
      setEditObject({
        ...editObject,
        numFibersPL: parseFloat(inputValue),
      });
    } else if (event.target.id === "testersFHD") {
      if (inputValue === "true") {
        inputValue = true;
      } else {
        inputValue = false;
      }
      setEditObject({
        ...editObject,
        testersFHD: inputValue,
      });
    } else if (event.target.id === "numUnits") {
      setEditObject({
        ...editObject,
        numUnits: parseFloat(inputValue),
      });
    } else if (event.target.id === "type") {
      setEditObject({
        ...editObject,
        type: inputValue.toString(),
      });
    } else if (event.target.id === "problemType") {
      setEditObject({
        ...editObject,
        problemType: inputValue,
      });
    }
  };

  //console.log("acData",acData)
  const dialogContent = () => {
    let data = null;
    let index = editIndex;
    if (index !== -1) {
      data = acData[index];
      if (projectStageInput === "Activities") {
        const commonContentFront = (
          <>
            <TextField
              margin="dense"
              id="crewID"
              label="Crew ID"
              defaultValue={data.crewID}
              disabled={true}
              fullWidth
              //onChange={handleEditChange}
            />
            <TextField
              margin="dense"
              id="numCrew"
              label="# Crew"
              fullWidth
              defaultValue={data.numCrew}
              onChange={handleEditChange}
            />
            <TextField
              margin="dense"
              id="numHours"
              label="# Hours"
              fullWidth
              defaultValue={data.numHours}
              onChange={handleEditChange}
            />
          </>
        );

        if (props.barInput.dataType === "Drilling/Conduit Placing") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="completion"
                label="Completion"
                fullWidth
                defaultValue={data.completion}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="unit"
                label="Unit"
                fullWidth
                select
                defaultValue={data.unit}
                value={editObject.unit}
                onChange={handleEditChange}
                SelectProps={{
                  native: true,
                }}
              >
                {Unit.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </DialogContent>
          );
        } else if (props.barInput.dataType === "FP/Drop Stubouts") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="quantity"
                label="Quantity"
                fullWidth
                defaultValue={data.quantity}
                onChange={handleEditChange}
              />
            </DialogContent>
          );
        } else if (props.barInput.dataType === "Hydrovac") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="numPotholes"
                label="# Potholes"
                fullWidth
                defaultValue={data.numPotholes}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="numTrips"
                label="# Trips"
                fullWidth
                defaultValue={data.numTrips}
                onChange={handleEditChange}
              />
            </DialogContent>
          );
        } else if (
          props.barInput.dataType === "Hand Pulling/Distribution Cable"
        ) {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="length"
                label="length"
                fullWidth
                defaultValue={data.length}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="unit"
                label="Unit"
                fullWidth
                select
                defaultValue={data.unit}
                value={editObject.unit}
                onChange={handleEditChange}
                SelectProps={{
                  native: true,
                }}
              >
                {Unit.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </DialogContent>
          );
        } else if (props.barInput.dataType === "Hand Pulling/Drops") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="dropCount"
                label="DropCount"
                fullWidth
                defaultValue={data.dropCount}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="dropLength"
                label="DropLength"
                fullWidth
                defaultValue={data.dropLength}
                onChange={handleEditChange}
              />
            </DialogContent>
          );
        } else if (props.barInput.dataType === "Hand Pulling/Terminal Tails") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="metersPlaced"
                label="MetersPlaced"
                fullWidth
                defaultValue={data.metersPlaced}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="numTerminals"
                label="# Terminals"
                fullWidth
                defaultValue={data.numTerminals}
                onChange={handleEditChange}
              />
            </DialogContent>
          );
        } else if (props.barInput.dataType === "Jetting/Distribution Cable") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="length"
                label="length"
                fullWidth
                defaultValue={data.length}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="unit"
                label="Unit"
                fullWidth
                select
                defaultValue={data.unit}
                value={editObject.unit}
                onChange={handleEditChange}
                SelectProps={{
                  native: true,
                }}
              >
                {Unit.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </DialogContent>
          );
        } else if (props.barInput.dataType === "Jetting/Drops") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="dropLength"
                label="DropLength"
                fullWidth
                defaultValue={data.dropLength}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="dropCount"
                label="DropCount"
                fullWidth
                defaultValue={data.dropCount}
                onChange={handleEditChange}
              />
            </DialogContent>
          );
        } else if (props.barInput.dataType === "Jetting/Terminal Tails") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="metersPlaced"
                label="MetersPlaced"
                fullWidth
                defaultValue={data.metersPlaced}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="numTerminals"
                label="# Terminals"
                fullWidth
                defaultValue={data.numTerminals}
                onChange={handleEditChange}
              />
            </DialogContent>
          );
        } else if (props.barInput.dataType === "OTDR") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="numFibers"
                label="# Fibers"
                fullWidth
                defaultValue={data.numFibers}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="numTesters"
                label="# Testers"
                fullWidth
                defaultValue={data.numTesters}
                onChange={handleEditChange}
              />
            </DialogContent>
          );
        } else if (props.barInput.dataType === "Power Meter") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="numFibers"
                label="# Fibers"
                fullWidth
                defaultValue={data.numFibers}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="numTerminals"
                label="# Terminals"
                fullWidth
                defaultValue={data.numTerminals}
                onChange={handleEditChange}
              />
            </DialogContent>
          );
        } else if (props.barInput.dataType === "Ruby Red") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="numFibersPL"
                label="# FibersPL"
                fullWidth
                defaultValue={data.numFibersPL}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="numTesters"
                label="# Testers"
                fullWidth
                defaultValue={data.numTesters}
                onChange={handleEditChange}
              />
              {/* TODO change to boolean */}
              <TextField
                margin="dense"
                id="testersFHD"
                label="# testersFHD"
                fullWidth
                select
                defaultValue={data.testersFHD}
                value={editObject.testersFHD}
                onChange={handleEditChange}
                SelectProps={{
                  native: true,
                }}
              >
                {Check.map((option) => (
                  <option key={option.option} value={option.value}>
                    {option.option}
                  </option>
                ))}
              </TextField>
            </DialogContent>
          );
        } else if (props.barInput.dataType === "SB Placement") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="numUnits"
                label="# Units"
                fullWidth
                defaultValue={data.numUnits}
                onChange={handleEditChange}
              />

              <TextField
                margin="dense"
                id="type"
                label="Type"
                fullWidth
                select
                defaultValue={data.type}
                value={editObject.type}
                onChange={handleEditChange}
                SelectProps={{
                  native: true,
                }}
              >
                {SBType.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </DialogContent>
          );
        } else if (props.barInput.dataType === "Splicing") {
          return (
            <DialogContent>
              {commonContentFront}
              <TextField
                margin="dense"
                id="numWL"
                label="numWL"
                fullWidth
                defaultValue={data.numWL}
                onChange={handleEditChange}
              />
            </DialogContent>
          );
        }
      } else {
        //Problem
        var issueSelecter = null;
        if (props.barInput.dataType === "Drilling/Conduit Placing") {
          issueSelecter = issues.Drilling;
        } else if (props.barInput.dataType === "FP/Drop Stubouts") {
          issueSelecter = issues.FP;
        } else if (props.barInput.dataType === "Hydrovac") {
          issueSelecter = issues.Hydrovac;
        } else if (
          props.barInput.dataType === "Hand Pulling/Distribution Cable"
        ) {
          issueSelecter = issues.HandPullingDistributionCable;
        } else if (props.barInput.dataType === "Hand Pulling/Drops") {
          issueSelecter = issues.HandPullingDrops;
        } else if (props.barInput.dataType === "Hand Pulling/Terminal Tails") {
          issueSelecter = issues.HandPullingTerminalTails;
        } else if (props.barInput.dataType === "Jetting/Distribution Cable") {
          issueSelecter = issues.JettingDistributionCable;
        } else if (props.barInput.dataType === "Jetting/Drops") {
          issueSelecter = issues.JettingDrops;
        } else if (props.barInput.dataType === "Jetting/Terminal Tails") {
          issueSelecter = issues.JettingTerminalTails;
        } else if (props.barInput.dataType === "OTDR") {
          issueSelecter = issues.OTDR;
        } else if (props.barInput.dataType === "Power Meter") {
          issueSelecter = issues.PowerMeter;
        } else if (props.barInput.dataType === "Ruby Red") {
          issueSelecter = issues.RubyRed;
        } else if (props.barInput.dataType === "SB Placement") {
          issueSelecter = issues.SBPlacement;
        } else if (props.barInput.dataType === "Splicing") {
          issueSelecter = issues.Splicing;
        }
        return (
          <DialogContent>
            <TextField
              margin="dense"
              id="numHours"
              label="# Hours"
              defaultValue={data.numHours}
              fullWidth
              onChange={handleEditChange}
            />
            <TextField
              margin="dense"
              id="problemType"
              label="Problem Type"
              fullWidth
              select
              defaultValue={data.problemType}
              value={editObject.problemType}
              onChange={handleEditChange}
              SelectProps={{
                native: true,
              }}
            >
              {issueSelecter.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </DialogContent>
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      {acData == null || showNoData ? (
        <div className="noDataTable">
          <div>NO DATA AVAILABLE</div>
        </div>
      ) : (
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            userInput={props.barInput.dataType}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={acData.length}
                headCells={headCells}
              />
              <TableBody>
                {stableSort(acData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        //hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            //onClick={(event) => handleClick(event, row.id)}
                            inputProps={{ "aria-labelledby": labelId }}
                            id={labelId}
                            scope="row"
                          />
                        </TableCell>
                        {displayLevelInput === "Activity" ? (
                          <TableCell> {row.user} </TableCell>
                        ) : null}

                        {TableCells(row)}

                        {displayLevelInput === "Activity" ? (
                          <TableCell scope="row">
                            <IconButton
                              color="secondary"
                              onClick={() => handleEdit(row.id)}
                            >
                              <CreateOutlinedIcon fontSize="small" />
                            </IconButton>
                            {projectStageInput === "Activities" ? (
                              <IconButton
                                color="secondary"
                                onClick={() => handleDelete(row.activityId)}
                              >
                                <DeleteOutlinedIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              <IconButton
                                color="secondary"
                                onClick={() => handleDelete(row.problemId)}
                              >
                                <DeleteOutlinedIcon fontSize="small" />
                              </IconButton>
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={acData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* edit dialog */}
      <Dialog
        open={editDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.barInput.dataType}
        </DialogTitle>
        {dialogContent()}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* delete dialog */}
      <Dialog
        open={deleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.barInput.dataType}
        </DialogTitle>
        Are you sure to delete this data?
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EnhancedTable = forwardRef(EnhancedTable);
