import React, { useState, useImperativeHandle, forwardRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import xrange from "highcharts/modules/xrange";
import { Button } from "@material-ui/core";

import {
  creatDateAxis,
  creatYRawValue,
  creatYValuePer,
  creatYRawValueMulti,
  creatYPieRaw,
  creatYPiePercent,
  reformDate,
  getMed,
  getAvg,
  getSTD,
  getSequenceData,
  getPercentage,
  getProblemtoActivityPercentage,
  getSequenceDataDay,
  getPercentageDay,
  getSequenceDataMulti,
  getSequenceDataDayMulti,
  getPercentageMulti,
  getPercentageDayMulti,
  getActivityType,
  toDecimal2,
} from "../data/process";
import { Firestore } from "../firebase/firestore";
import {
  projectItems,
  cablingItems,
  civilItems,
  splicingItems,
  testingItems,
} from "../data/const";

HighchartsMore(Highcharts);
xrange(Highcharts);

export let Graphical = (props, ref) => {
  useImperativeHandle(ref, () => ({
    showData: (dataType, unit) => {
      handleGetData(dataType, unit);
    },
  }));
  const pid = props.drawerInput.project;
  const viewStartDate = new Date(props.startDate);
  const viewEndDate = new Date(props.endDate + "T23:59:59");
  const [options, setOptions] = useState({
    chart: {
      type: null,
      zoomType: "x",
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: null,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    series: [
      {
        name: "",
        data: null,
      },
    ],
  });
  const [median, setMedian] = useState();
  const [avg, setAvg] = useState();
  const [std, setSTD] = useState();
  const [showNoData, setShowNoData] = useState(true);

  const handleGetData = (dataType, unit) => {
    if (dataType !== null && unit !== null) {
      var startTime = null;
      var endTime = null;
      var xAxis = null;
      var seriesData = null;
      var unitY = null;
      var dataArray = [];
      var chartType = null;
      var xTitle = null;
      setMedian(null);
      setAvg(null);
      setSTD(null);
      if (props.drawerInput.displayLevel === "Activity") {
        if (props.drawerInput.projectStage === "Activities") {
          let activityType = getActivityType(dataType).ac;
          let subActivityType = getActivityType(dataType).sub;
          Firestore.Activity.GetDataActivityLevel(
            pid,
            viewStartDate,
            viewEndDate,
            activityType,
            subActivityType
          ).then((snapshot) => {
            if (snapshot.size !== 0) {
              setShowNoData(false);
              startTime = snapshot.docs[0].data().dateTime.toDate();
              endTime = snapshot.docs[snapshot.size - 1]
                .data()
                .dateTime.toDate();
              xAxis = creatDateAxis(startTime, endTime);
              let value = null;
              var lengthData = snapshot.docs.map((doc) => {
                if (
                  dataType === "Drilling/Conduit Placing" ||
                  dataType === "Hand Pulling/Distribution Cable" ||
                  dataType === "Jetting/Distribution Cable"
                ) {
                  value = doc.data().length;
                  if (doc.data().unit === "Inch") {
                    value = value * 0.0254;
                  }
                  if (doc.data().unit === "Feet") {
                    value = value * 0.3048;
                  }
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: value,
                  };
                } else if (dataType === "FP/Drop Stubouts") {
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: doc.data().quantity,
                  };
                } else if (dataType === "Hydrovac") {
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: doc.data().numPotholes,
                  };
                } else if (
                  dataType === "Hand Pulling/Drops" ||
                  dataType === "Jetting/Drops"
                ) {
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: parseFloat(
                      doc
                        .data()
                        .dropLength.slice(0, doc.data().dropLength.length - 1)
                    ),
                  };
                } else if (
                  dataType === "Hand Pulling/Terminal Tails" ||
                  dataType === "Jetting/Terminal Tails"
                ) {
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: doc.data().metersPlaced,
                  };
                } else if (dataType === "OTDR") {
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: doc.data().numFibers,
                  };
                } else if (dataType === "Power Meter") {
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: doc.data().numFibers,
                  };
                } else if (dataType === "Ruby Red") {
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: doc.data().numFibersPL,
                  };
                } else if (dataType === "SB Placement") {
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: doc.data().numUnits,
                  };
                } else if (dataType === "Splicing") {
                  return {
                    date: reformDate(doc.data().dateTime.toDate()),
                    value: doc.data().numWL,
                  };
                }
              });
              let hourData = snapshot.docs.map((doc) => {
                return {
                  date: reformDate(doc.data().dateTime.toDate()),
                  value: doc.data().numHours,
                };
              });
              let crewData = snapshot.docs.map((doc) => {
                return {
                  date: reformDate(doc.data().dateTime.toDate()),
                  value: doc.data().numCrew,
                };
              });
              if (unit === "Total Completion") {
                dataArray = creatYRawValue(xAxis, lengthData);
                seriesData = [{ name: "Total Completion", data: dataArray }];
                unitY = "meter";

                xTitle = "Time";
              }
              if (unit === "Worked hours") {
                dataArray = creatYRawValue(xAxis, hourData);
                seriesData = [{ name: "Worked hours", data: dataArray }];
                unitY = "Hour";
              }
              if (unit === "Production rate") {
                let lengthArray = creatYRawValue(xAxis, lengthData);
                let hourArray = creatYRawValue(xAxis, hourData);
                dataArray = creatYValuePer(lengthArray, hourArray);
                dataArray = dataArray.map((value) =>
                  parseFloat(value.toFixed(2))
                );
                seriesData = [{ name: "Production rate", data: dataArray }];
                unitY = "Production rate(m/h)";
              }
              if (unit === "Production rate per crew") {
                let lengthArray = creatYRawValue(xAxis, lengthData);
                let hourArray = creatYRawValue(xAxis, hourData);
                let crewArray = creatYRawValue(xAxis, crewData);
                dataArray = creatYValuePer(
                  creatYValuePer(lengthArray, hourArray),
                  crewArray
                );
                dataArray = dataArray.map((value) =>
                  parseFloat(value.toFixed(2))
                );
                seriesData = [
                  { name: "Production rate per crew", data: dataArray },
                ];
                unitY = "Production rate(m/h/crew)";
              }
              setMedian(toDecimal2(getMed(dataArray)));
              setAvg(toDecimal2(getAvg(dataArray)));
              setSTD(toDecimal2(getSTD(dataArray)));
              setOptions({
                //...options,
                chart: {
                  type: "column",
                },
                title: {
                  text: dataType,
                },
                xAxis: {
                  categories: xAxis,
                },
                yAxis: {
                  title: {
                    text: unitY,
                  },
                  categories: null,
                },
                plotOptions: {},
                series: seriesData,
              });
            } else {
              setShowNoData(true);
            }
          });
        } else {
          //Problem
          let activityType = getActivityType(dataType).ac;
          let subActivityType = getActivityType(dataType).sub;
          Firestore.Problem.GetActivityProblem(
            pid,
            viewStartDate,
            viewEndDate,
            activityType,
            subActivityType
          ).then((snapshot) => {
            if (snapshot.size !== 0) {
              setShowNoData(false);
              startTime = snapshot.docs[0].data().dateTime.toDate();
              endTime = snapshot.docs[snapshot.size - 1]
                .data()
                .dateTime.toDate();
              xAxis = creatDateAxis(startTime, endTime);
              var hourDataProblem = snapshot.docs.map((doc) => {
                return {
                  date: reformDate(doc.data().dateTime.toDate()),
                  rawDate: doc.data().dateTime.toDate(),
                  type: doc.data().issueType,
                  value: doc.data().numHours,
                };
              });
              if (unit === "Total Problem Hours") {
                dataArray = creatYRawValue(xAxis, hourDataProblem);
                seriesData = [{ name: "Total Problem Hours", data: dataArray }];
                unitY = "Hour";
                chartType = "column";
                setMedian(toDecimal2(getMed(dataArray)));
                setAvg(toDecimal2(getAvg(dataArray)));
                setSTD(toDecimal2(getSTD(dataArray)));
              }
              if (unit === "Problem Type-Hr") {
                dataArray = creatYRawValueMulti(xAxis, hourDataProblem);
                seriesData = dataArray;
                unitY = "Hour";
                chartType = "column";
              }
              if (unit === "Problem Type-Total Hr") {
                dataArray = creatYPieRaw(hourDataProblem);
                seriesData = [
                  { name: "Problem Type-Total Hr", data: dataArray },
                ];
                chartType = "pie";
              }
              if (unit === "Problem Type-Percentage") {
                dataArray = creatYPiePercent(hourDataProblem);
                seriesData = [
                  { name: "Problem Type-Total Hr", data: dataArray },
                ];
                chartType = "pie";
              }
              setOptions({
                //...options,
                chart: {
                  type: chartType,
                },
                title: {
                  text: dataType,
                },
                xAxis: {
                  categories: xAxis,
                },
                yAxis: {
                  title: {
                    text: unitY,
                  },
                  categories: null,
                },
                series: seriesData,
              });
              if (unit === "Problem Time to Activity Time(Percentage)") {
                Firestore.Activity.GetDataActivityLevel(
                  pid,
                  viewStartDate,
                  viewEndDate,
                  activityType,
                  subActivityType
                ).then((snapshot) => {
                  var hourDataActivity = snapshot.docs.map((doc) => {
                    return {
                      date: doc.data().dateTime.toDate(),
                      value: doc.data().numHours,
                    };
                  });
                  let startTimeAc = snapshot.docs[0].data().dateTime.toDate();
                  let endTimeAc = snapshot.docs[snapshot.size - 1]
                    .data()
                    .dateTime.toDate();
                  startTime = startTimeAc < startTime ? startTimeAc : startTime;
                  endTime = endTimeAc > endTime ? endTimeAc : endTime;
                  xAxis = creatDateAxis(startTime, endTime);
                  seriesData = [
                    {
                      name: "Problem Time to Activity Time(Percentage)",
                      data: getProblemtoActivityPercentage(
                        xAxis,
                        hourDataActivity,
                        hourDataProblem
                      ),
                    },
                  ];
                  setOptions({
                    //...options,
                    chart: {
                      type: "column",
                    },
                    title: {
                      text: dataType,
                    },
                    subTitle: {
                      text: unit,
                    },
                    xAxis: {
                      categories: xAxis,
                    },
                    yAxis: {
                      title: {
                        text: "Percentage %",
                      },
                      categories: null,
                    },
                    series: seriesData,
                  });
                });
              }
              if (
                unit === "Problem-Activity Sequence/Time(Percentage-Relative)"
              ) {
                //hour
                Firestore.Activity.GetDataActivityLevel(
                  pid,
                  viewStartDate,
                  viewEndDate,
                  activityType,
                  subActivityType
                ).then((snapshot) => {
                  if (snapshot.size !== 0) {
                    var hourDataActivity = snapshot.docs.map((doc) => {
                      return {
                        date: doc.data().dateTime.toDate(),
                        value: doc.data().numHours,
                      };
                    });
                    seriesData = [
                      {
                        name: unit,
                        data: getSequenceData(
                          hourDataActivity,
                          hourDataProblem
                        ),
                      },
                    ];

                    let percentage = getPercentage(
                      hourDataActivity,
                      hourDataProblem
                    );
                    console.log("seriesData", seriesData);
                    setOptions({
                      //...options,
                      chart: {
                        type: "xrange",
                      },
                      title: {
                        text: dataType,
                      },
                      xAxis: {
                        type: "datetime",
                        labels: {
                          formatter: function () {
                            return Highcharts.dateFormat(
                              "%y-%m-%d",
                              this.value
                            );
                          },
                          step: 0,
                        },
                      },
                      yAxis: {
                        categories: [
                          "Activity " + percentage.ac + "%",
                          "Problem " + percentage.prob + "%",
                        ],
                      },
                      plotOptions: {
                        series: {
                          borderRadius: 0,
                          borderWidth: 1,
                          grouping: false,
                          pointPadding: 0,
                          groupPadding: 0,
                        },
                      },
                      series: seriesData,
                    });
                  } else {
                    console.log("empty");
                  }
                });
              }
              if (
                unit === "Problem-Activity Sequence/Time(Percentage-Total Time)"
              ) {
                //Day
                Firestore.Activity.GetDataActivityLevel(
                  pid,
                  viewStartDate,
                  viewEndDate,
                  activityType,
                  subActivityType
                ).then((snapshot) => {
                  if (snapshot.size !== 0) {
                    var hourDataActivity = snapshot.docs.map((doc) => {
                      return {
                        date: doc.data().dateTime.toDate(),
                        value: doc.data().numHours,
                      };
                    });
                    seriesData = [
                      {
                        name: unit,
                        data: getSequenceDataDay(
                          hourDataActivity,
                          hourDataProblem
                        ),
                      },
                    ];
                    let percentage = getPercentageDay(
                      hourDataActivity,
                      hourDataProblem
                    );
                    setOptions({
                      //...options,
                      chart: {
                        type: "xrange",
                      },
                      title: {
                        text: unit,
                      },
                      xAxis: {
                        type: "datetime",
                        labels: {
                          formatter: function () {
                            return Highcharts.dateFormat(
                              "%Y-%m-%d",
                              this.value
                            );
                          },
                        },
                      },
                      yAxis: {
                        categories: [
                          "Activity " + toDecimal2(percentage.ac) + "%",
                          "Problem " + toDecimal2(percentage.prob) + "%",
                        ],
                      },
                      plotOptions: {
                        series: {
                          borderRadius: 0,
                          borderWidth: 1,
                          grouping: false,
                          pointPadding: 0,
                          groupPadding: 0,
                        },
                      },
                      series: seriesData,
                    });
                  }
                });
              }
            } else {
              setShowNoData(true);
            }
          });
        }
      } else if (props.drawerInput.displayLevel === "Category") {
        let dataItems = null;
        if (dataType === "Cabling") {
          dataItems = cablingItems;
        } else if (dataType === "Civil") {
          dataItems = civilItems;
        } else if (dataType === "Splicing") {
          dataItems = splicingItems;
        } else if (dataType === "Testing") {
          dataItems = testingItems;
        }
        if (unit === "Activity Sequence/Time(Percentage-Relative Time)") {
          Firestore.Activity.GetCategoryData(
            pid,
            viewStartDate,
            viewEndDate,
            dataType
          ).then((snapshot) => {
            if (snapshot.size !== 0) {
              setShowNoData(false);
              let ActivityData = snapshot.docs.map((doc) => {
                let activityType = null;
                if (doc.data().category === "Splicing") {
                  activityType = "Splicing";
                } else if (
                  doc.data().activityType === "Hand Pulling" ||
                  doc.data().activityType === "Jetting"
                ) {
                  activityType =
                    doc.data().activityType + "/" + doc.data().subActivityType;
                } else {
                  activityType = doc.data().activityType;
                }
                return {
                  type: activityType,
                  value: doc.data().numHours,
                  date: doc.data().dateTime.toDate(),
                };
              });
              Firestore.Problem.GetCategoryProblem(
                pid,
                viewStartDate,
                viewEndDate,
                dataType
              ).then((snapshot) => {
                let categoryX = [];
                let ProblemData = null;
                if (snapshot.size !== 0) {
                  let ProblemData = snapshot.docs.map((doc) => {
                    let problemType = null;
                    if (doc.data().category === "Splicing") {
                      problemType = "Splicing";
                    } else if (
                      doc.data().activityType === "Hand Pulling" ||
                      doc.data().activityType === "Jetting"
                    ) {
                      problemType =
                        doc.data().activityType +
                        "/" +
                        doc.data().subActivityType;
                    } else {
                      problemType = doc.data().activityType;
                    }
                    return {
                      type: problemType,
                      value: doc.data().numHours,
                      date: doc.data().dateTime.toDate(),
                    };
                  });
                }
                seriesData = [
                  {
                    name: "Activity Sequence/Time(Percentage-Relative Time)",
                    data: getSequenceDataMulti(
                      ActivityData,
                      ProblemData,
                      dataItems
                    ),
                  },
                ];

                let percent = getPercentageMulti(
                  ActivityData,
                  ProblemData,
                  dataItems
                );
                for (let i = 0; i < dataItems.length; i++) {
                  categoryX.push(dataItems[i] + " " + percent[i] + "%");
                }
                console.log("categoryX", categoryX);

                setOptions({
                  //...options,
                  chart: {
                    type: "xrange",
                  },
                  title: {
                    text: unit,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      formatter: function () {
                        return Highcharts.dateFormat("%Y-%m-%d", this.value);
                      },
                    },
                  },
                  yAxis: {
                    title: {
                      text: "",
                    },
                    categories: categoryX,
                  },
                  plotOptions: {
                    series: {
                      borderRadius: 0,
                      borderWidth: 1,
                      grouping: false,
                      pointPadding: 0,
                      groupPadding: 0,
                    },
                  },
                  series: seriesData,
                });
              });
            } else {
              setShowNoData(true);
            }
          });
        }
        if (unit === "Activity Sequence/Time(Percentage-Total Time)") {
          Firestore.Activity.GetCategoryData(
            pid,
            viewStartDate,
            viewEndDate,
            dataType
          ).then((snapshot) => {
            if (snapshot.size !== 0) {
              setShowNoData(false);
              let ActivityData = snapshot.docs.map((doc) => {
                let activityType = null;
                if (doc.data().category === "Splicing") {
                  activityType = "Splicing";
                } else if (
                  doc.data().activityType === "Hand Pulling" ||
                  doc.data().activityType === "Jetting"
                ) {
                  activityType =
                    doc.data().activityType + "/" + doc.data().subActivityType;
                } else {
                  activityType = doc.data().activityType;
                }
                return {
                  type: activityType,
                  value: doc.data().numHours,
                  date: doc.data().dateTime.toDate(),
                };
              });
              Firestore.Problem.GetCategoryProblem(
                pid,
                viewStartDate,
                viewEndDate,
                dataType
              ).then((snapshot) => {
                let ProblemData = null;
                if (snapshot.size !== 0) {
                  ProblemData = snapshot.docs.map((doc) => {
                    let problemType = null;
                    if (doc.data().category === "Splicing") {
                      problemType = "Splicing";
                    } else if (
                      doc.data().activityType === "Hand Pulling" ||
                      doc.data().activityType === "Jetting"
                    ) {
                      problemType =
                        doc.data().activityType +
                        "/" +
                        doc.data().subActivityType;
                    } else {
                      problemType = doc.data().activityType;
                    }
                    return {
                      type: problemType,
                      value: doc.data().numHours,
                      date: doc.data().dateTime.toDate(),
                    };
                  });
                }
                seriesData = [
                  {
                    name: unit,
                    data: getSequenceDataDayMulti(
                      ActivityData,
                      ProblemData,
                      dataItems
                    ),
                  },
                ];
                let percent = getPercentageDayMulti(
                  ActivityData,
                  ProblemData,
                  dataItems
                );
                let categoryX = [];
                for (let i = 0; i < dataItems.length; i++) {
                  categoryX.push(dataItems[i] + " " + percent[i] + "%");
                }
                setOptions({
                  //...options,
                  chart: {
                    type: "xrange",
                  },
                  title: {
                    text: unit,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      formatter: function () {
                        return Highcharts.dateFormat("%Y-%m-%d", this.value);
                      },
                    },
                  },
                  yAxis: {
                    categories: categoryX,
                  },
                  plotOptions: {
                    series: {
                      borderRadius: 0,
                      borderWidth: 1,
                      grouping: false,
                      pointPadding: 0,
                      groupPadding: 0,
                    },
                  },
                  series: seriesData,
                });
              });
            } else {
              setShowNoData(true);
            }
          });
        }
        if (unit === "Activity Time(Percentage-Relative Time)") {
          Firestore.Activity.GetCategoryData(
            pid,
            viewStartDate,
            viewEndDate,
            dataType
          ).then((snapshot) => {
            if (snapshot.size !== 0) {
              setShowNoData(false);
              let rawData = snapshot.docs.map((doc) => {
                let activityType = null;
                if (doc.data().category === "Splicing") {
                  activityType = "Splicing";
                } else if (
                  doc.data().activityType === "Hand Pulling" ||
                  doc.data().activityType === "Jetting"
                ) {
                  activityType =
                    doc.data().activityType + "/" + doc.data().subActivityType;
                } else {
                  activityType = doc.data().activityType;
                }
                return {
                  type: activityType,
                  value: doc.data().numHours,
                };
              });
              dataArray = creatYPiePercent(rawData);
              seriesData = [
                {
                  name: "Activity Time(Percentage-Relative Time)",
                  data: dataArray,
                },
              ];
              chartType = "pie";
            } else {
              setShowNoData(true);
            }
            setOptions({
              //...options,
              chart: {
                type: chartType,
              },
              title: {
                text: dataType,
              },
              tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f} %</b>",
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  },
                },
              },
              series: seriesData,
            });
          });
        }
      } else {
        //Project
        if (unit === "Project Analysis-Sequences") {
          Firestore.Activity.GetAllActivity(
            pid,
            viewStartDate,
            viewEndDate
          ).then((snapshot) => {
            if (snapshot.size !== 0) {
              setShowNoData(false);
              let ActivityData = snapshot.docs.map((doc) => {
                return {
                  type: doc.data().category,
                  value: doc.data().numHours,
                  date: doc.data().dateTime.toDate(),
                };
              });
              Firestore.Problem.GetAllProblem(
                pid,
                viewStartDate,
                viewEndDate
              ).then((snapshot) => {
                let ProblemData = null;
                if (snapshot.size !== 0) {
                  let ProblemData = snapshot.docs.map((doc) => {
                    return {
                      type: doc.data().category,
                      value: doc.data().numHours,
                      date: doc.data().dateTime.toDate(),
                    };
                  });
                }
                seriesData = [
                  {
                    name: unit,
                    data: getSequenceDataMulti(
                      ActivityData,
                      ProblemData,
                      projectItems
                    ),
                  },
                ];
                let percent = getPercentageMulti(
                  ActivityData,
                  ProblemData,
                  projectItems
                );
                let categoryX = [];
                for (let i = 0; i < projectItems.length; i++) {
                  categoryX.push(projectItems[i] + " " + percent[i] + "%");
                }
                setOptions({
                  //...options,
                  chart: {
                    type: "xrange",
                  },
                  title: {
                    text: unit,
                  },
                  xAxis: {
                    type: "datetime",
                    labels: {
                      formatter: function () {
                        return Highcharts.dateFormat("%Y-%m-%d", this.value);
                      },
                    },
                  },
                  yAxis: {
                    categories: categoryX,
                  },
                  plotOptions: {
                    series: {
                      borderRadius: 0,
                      borderWidth: 1,
                      grouping: false,
                      pointPadding: 0,
                      groupPadding: 0,
                    },
                  },
                  series: seriesData,
                });
              });
            } else {
              setShowNoData(true);
            }
          });
        }
      }
    } else {
      setOptions({
        chart: {
          type: null,
          zoomType: "x",
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: null,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: null,
          },
        },
        plotOptions: {},
        series: [
          {
            name: "",
            data: null,
          },
        ],
      });
    }
  };

  return (
    <>
      {showNoData ? (
        <div className="noDataTable">
          <div>NO DATA AVAILABLE</div>
        </div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
      {props.drawerInput.displayLevel !== "Activity" ? null : (
        <>
          <div> Median: {median}</div>
          <div> Average: {avg} </div>
          <div> STD: {std} </div>
        </>
      )}

      {/* <Button
        onClick={() =>
          handleGetData(props.drawerInput.dataType, props.drawerInput.unit)
        }
      >
        GET DATA
      </Button> */}
    </>
  );
};

Graphical = forwardRef(Graphical);
