import { makeStyles } from "@material-ui/core/styles";

const primary = "#FD9426";
const secondary = "#FFBC75";

export const styles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: primary,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  indicator: {
    height: 100,
    zIndex: 1,
    backgroundColor: secondary,
  },
  defaultTab: {
    color: "black",
    fontWeight: "600",
    zIndex: 2,
  },
  selectedTab: {
    color: "white",
    fontWeight: "600",
    zIndex: 2,
  },
  logoutButton: {
    backgroundColor: primary,
    height: "70%",
    color: "white",
    marginTop: "5px",
  },
  formControl: {
    width: "15%",
    height: "80%",
    marginTop: "8px",
    backgroundColor: "white",
  },
}));
