import React, { useState } from "react";
import "../App.css";
import { Privacy } from "./Privacy";

export const Footer = () => {
  const [privacyScreen, setPrivacyScreen] = useState(false);

  return (
    <div>
      <div className="bottomLine">
        {/* footer */}
        <p>© 2021 Job Site Update</p>
        <p>Find us on Social Media (Social Media Icons)</p>
        {/* TODO: open privacy policy */}
        <p>
          <a
            href="#"
            style={{ textDecoration: "none", color: "white" }}
            title="Check our Privacy Policy"
            onClick={() => {
              setPrivacyScreen(!privacyScreen);
            }}
          >
            Privacy Policy
          </a>
        </p>
      </div>
      <div>
        {privacyScreen ? <Privacy setPrivacyScreen={setPrivacyScreen} /> : null}
      </div>
    </div>
  );
};
