import React, { useEffect } from "react";
import { Footer } from "../component/Footer";
import { Contact } from "../component/Contact";

export const Home = () => {
  useEffect(() => {
    document.title = "Home | JobSiteUpdate";
  }, []);

  return (
    <div>
      <div
        className="page-content fullpage"
        style={{ backgroundColor: "#EDEDED" }}
      >
        <div className="half">
          <h1 style={{ fontSize: "28px", fontWeight: "normal" }}>
            Welcome to Job Site Update
          </h1>
        </div>
        <div className="half"></div>
      </div>
      <div className="fullpage topStyles" id="about">
        <div className="halfContainer bottomStyles">
          <div className="half">
            <img
              style={{ height: "60vh" }}
              src="first.png"
              alt="Phone with a hard hat"
            />
          </div>
          <div className="half">
            <p>
              Upload your job site data to the cloud <br />
              Anywhere, anytime...{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="fullpage topStyles">
        <div className="halfContainer bottomStyles">
          <div className="half">
            <p>
              Data driven
              <br />
              project monitoring{" "}
            </p>
          </div>
          <div className="half">
            <img
              style={{ height: "60vh" }}
              src="project_monitoring.png"
              alt="iPad showing a graphical pipeline"
            />
          </div>
        </div>
      </div>
      <div className="fullpage topStyles">
        <div className="halfContainer bottomStyles">
          <div className="half">
            <img
              style={{ height: "60vh" }}
              src="third.png"
              alt="monitor showing a graph"
            />
          </div>
          <div className="half">
            <p>
              Live interactive
              <br />
              analytics reports{" "}
            </p>
          </div>
        </div>
      </div>
      <div
        className="fullBottom"
        style={{
          backgroundImage: `url(${"footer.png"})`,
          backgroundPosition: "right bottom",
          backgroundSize: "100% auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="content" id="download">
          <h2 style={{ fontWeight: "normal", paddingBottom: "20px" }}>
            Download Now!
          </h2>
        </div>
        <div className="homeFooter">
          <div
            className="images"
            style={{ display: "flex", textAlign: "center" }}
          >
            <div style={{ width: "20%", flex: "20%" }}>
              <a
                href="https://apps.apple.com/ca/app/job-site-update/id1570504842"
                target="_blank"
                rel="noreferrer"
                title="Download on Apple Store"
              >
                <img
                  src="appleStore.png"
                  alt="Download on the Apple store icon"
                />
              </a>
            </div>
            <div style={{ width: "20%", flex: "20%" }}>
              <a
                href="https://play.google.com/store/apps/details?id=com.arba.jobsiteupdate"
                target="_blank"
                rel="noreferrer"
                title="Download on Android"
              >
                <img
                  src="playStore.png"
                  alt="Download on the Google Play store icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <Contact />
      <Footer />
    </div>
  );
};
