import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  FormControl,
  NativeSelect,
  TextField,
  Checkbox,
} from "@material-ui/core";
import "../App.css";
import { BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import { reformDate } from "../data/process";
import { Firestore } from "../firebase/firestore.js";
import { ViewDataNav } from "../component/viewDataNav.jsx";

import firebase from "firebase";

export const ViewData = (props) => {
  useEffect(() => {
    document.title = "View Data | JobSiteUpdate";
    loadDataOnlyOnce();
  }, []);

  const [drawerState, setDrawerState] = React.useState(false);
  const [companyInfos, setCompanyInfos] = useState([]);
  const [projectInfos, setProjectInfos] = useState(null);
  const [projectStartDate, setProjectStartDate] = useState("");
  const [projectEndDate, setProjectEndDate] = useState("");
  const [projectName,setProjectName] = useState("");
  const [selectStartDate, setSelectStartDate] = useState("");
  const [selectEndDate, setSelectEndDate] = useState("");
  const [userDict,setUserDict] = useState()
  const uid = props.uid;
  const [inputState, setInputState] = React.useState({
    company: "",
    project: "",
    projectStage: "",
    entireProject: false,
    displayLevel: "",
  });
  const navRef = useRef();
  const updateChildOption = () => {
    navRef.current.resetOption();
  };
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState(!drawerState);
  };

  function loadDataOnlyOnce() {
    const cName = new Array();
    Firestore.Company.GetAllCompanyByUserRef(uid).then((querySnapShot) => {
      cName.push("");
      for (let i = 0; i < querySnapShot.size; i++) {
        cName.push({
          name: querySnapShot.docs[i].data().name,
          id: querySnapShot.docs[i].id,
        });
      }
    });
    console.log("cname")
    console.log(cName)
    setCompanyInfos(cName);
  }

  const handleInputChange = (event) => {
    const name = event.target.name;
    if (name === "entireProject") {
      setInputState({
        ...inputState,
        entireProject: event.target.checked,
      });

      setSelectStartDate(projectStartDate);
      setSelectEndDate(projectEndDate);
    } else if (name === "startDate") {
      if (event.target.value < projectStartDate) {
        setSelectStartDate(projectStartDate);
      } else {
        setSelectStartDate(event.target.value);
      }
    } else if (name === "endDate") {
      if (event.target.value > projectEndDate) {
        setSelectEndDate(projectEndDate);
      } else {
        setSelectEndDate(event.target.value);
      }
    } else if (name === "company") {
      var cid = event.target.value;
      var signInCompany = firebase.functions().httpsCallable("signInCompany");
      signInCompany({ companyUID: cid, userUID: uid }).then((result) => {
        var pName = new Array();
        Firestore.Project.GetAll(cid, uid).then((querySnapShot) => {
          pName.push("");
          for (let i = 0; i < querySnapShot.length; i++) {
            pName.push({
              name: querySnapShot[i].projectName,
              id: querySnapShot[i].id,
            });
          }
          setProjectInfos(pName);
        });
        setInputState({
          ...inputState,
          [name]: cid,
        });
        Firestore.User.GetAllUser().then((querySnapShot) =>{
          var dict = {}
          let tmp = querySnapShot.docs.map((doc) => {
            dict[doc.id] = doc.data().name
          })
          setUserDict(dict)
          //console.log(dict)
        })
      });
    } else if (name === "project") {
      Firestore.Project.GetProjectById(event.target.value).then((doc) => {
        if (doc.exists) {
          var pEndDate = reformDate(new Date());
          setProjectEndDate(pEndDate);
          var pstartDate = reformDate(doc.data().startDateTime.toDate());
          setProjectStartDate(pstartDate);
          setSelectStartDate(pstartDate);
          setSelectEndDate(pEndDate);
          setProjectName(doc.data().projectName)
        }
      });
      setInputState({
        ...inputState,
        [name]: event.target.value,
      });
    } else {
      setInputState({
        ...inputState,
        [name]: event.target.value,
      });
    }
    updateChildOption();
  };

 // console.log("inputState ",inputState)
  // console.log("testc", companyInfos)
  //console.log("drawerdate", selectStartDate,selectEndDate)

  return (

    <div
      className="viewDataRoot"
      style={{
        paddingTop: `${props.padding}px`,
        minHeight: `${window.innerHeight - props.padding}px`,
      }}
    >
      {/* Drawer */}
      {drawerState ? (
        <div className="drawer">
          <div className="drawerTitle">Data Display Settings</div>
          <div>Company</div>
          <NativeSelect
            className="drawerDropdown"
            value={inputState.company}
            onChange={handleInputChange}
            inputProps={{
              name: "company",
            }}
          >
            {companyInfos != null
              ? companyInfos.map((value) => {
                  return (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  );
                })
              : null}
          </NativeSelect>
          <div>Project</div>

          <NativeSelect
            className="drawerDropdown"
            value={inputState.project}
            onChange={handleInputChange}
            inputProps={{
              name: "project",
            }}
          >
            {projectInfos != null
              ? projectInfos.map((value) => {
                  return (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  );
                })
              : null}
          </NativeSelect>

          <div>Level</div>
          <NativeSelect
            className="drawerDropdown"
            value={inputState.displayLevel}
            onChange={handleInputChange}
            inputProps={{
              name: "displayLevel",
            }}
          >
            <option aria-label="None" value="" />
            <option value={"Activity"}>Activity</option>
            <option value={"Category"}>Category</option>
            <option value={"Project"}>Project</option>
          </NativeSelect>

          <div>Project Stage</div>
          <NativeSelect
            className="drawerDropdown"
            value={inputState.projectStage}
            onChange={handleInputChange}
            inputProps={{
              name: "projectStage",
            }}
            disabled={inputState.displayLevel === "Activity" ? false : true}
          >
            <option aria-label="None" value="" />
            <option value={"Activities"}>Activities</option>
            <option value={"Problems"}>Problems</option>
          </NativeSelect>

          <form>
            <div style={{ marginBottom: "20px" }}>
              <TextField
                name="startDate"
                label="Start Date"
                type="date"
                onChange={handleInputChange}
                disabled={inputState.entireProject}
                className="drawerInputDate"
                value={selectStartDate}
              />
            </div>
            <div>
              <TextField
                name="endDate"
                label="End Date"
                type="date"
                onChange={handleInputChange}
                disabled={inputState.entireProject}
                className="drawerInputDate"
                value={selectEndDate}
              />
            </div>
          </form>

          <div className="drawerCheckBox">
            <Checkbox
              color="primary"
              checked={inputState.entireProject}
              onChange={handleInputChange}
              name="entireProject"
            />
            Entire Project
          </div>

          <Button variant="outlined" onClick={toggleDrawer(false)}>
            CLOSE
          </Button>
        </div>
      ) : null}
      <div className="drawerButton" onClick={toggleDrawer(true)}>
        {drawerState ? <BsFillCaretLeftFill /> : <BsFillCaretRightFill />}
      </div>
      {/* Data Content */}
      <div className={drawerState ? "dataContentShift" : "dataContent"}>
        <div className="nameBanner"> {projectName!==""?projectName:"Project Name"} </div>
        <ViewDataNav
          ref={navRef}
          drawerInput={inputState}
          startDate={selectStartDate}
          endDate={selectEndDate}
          userDict={userDict}
        />
      </div>
    </div>
  );
};
